import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtModal from "../../components/NtModal";
import Logger from "../../common/utils/Logger";
import NtPinManagementNewForm from "../components/NtPinManagementNewForm";
import NtPinmanagementUploadForm from "../components/NtPinManagementUploadForm";
import { fetchPins } from "../../api/billing";
import NtPinManagementItem from "../components/NtPinManagementItem";
import {
  asyncStorageSetData,
  parseDecimalPlaces,
  parseSimpleDisplayDate,
  showToast,
} from "../../common/utils/ControllerUtils";
import settings from "../../config/settings";
import pincsvcolumndata from "../data/pincsvcolumdata";
import BasePinController from "./BasePinController";
import NtPinmanagementAdvanceSearchPicker from "../components/NtPinManagementAdvanceSearchPicker";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListHeader from "../../components/NtListHeader";
import NtListEmpty from "../../components/NtListEmpty";
import NtTabController from "../../components/NtTabController";
import pintypesdata from "../data/pintypesdata";
import NtArchiveCabinDataAllForm from "../components/NtArchiveCabinDataAllForm";

class PinManagementController extends BasePinController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
    selectedTab: null,
  };

  column_location = `@pin_column_storage`;

  page = 1;

  infiniteScrollEnabled = false;
  addAccessModalRef = React.createRef();
  uploadAccessModalRef = React.createRef();
  archiveCabinDataModalRef = React.createRef();
  advanceSearchRef = React.createRef();

  tabs = [
    {
      id: "all",
      title: "All",
    },
    {
      id: "active",
      title: "Active",
    },
    {
      id: "inactive",
      title: "Inactive",
    },
  ];

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.state.selectedTab = this.tabs[0];
    this.setState({ selectedTab: this.state.selectedTab });
    this.handleDefaultColumnsFetch(
      this.column_location,
      pincsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentDidUpdate(prevProps) {
    // Check if the params have changed
    if (prevProps.route.params !== this.props.route.params) {
      this.page = 1;
      this.handleDataFetch();
    }
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.moduleCallingCards);
  };

  handleDataFetch = async () => {
    Logger("Fetching Pins data");

    this.setState({ loading: true });

    const response = await fetchPins(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      this.generateFullFilter()
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.data?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.data,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.data),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Pin export data");

    const response = await fetchPins(
      this.context.user?.environment?.domain,
      1,
      10000000,
      this.state.searchText,
      this.generateFullFilter()
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      return response.data.data;
    }

    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "credit" || column == "initialbalance") {
      return item.type == "admin" ? "N/A" : parseDecimalPlaces(item[column]);
    } else if (
      column == "expirationdate" ||
      column == "creationdate" ||
      column == "lastuse"
    ) {
      return item[column] ? parseSimpleDisplayDate(item[column]) : "N/A";
    } else if (column == "status") {
      return item[column] == 1 ? "Active" : "Inactive";
    }

    return item[column];
  };

  handleTypeFetch = () => {
    return pintypesdata.find(
      (each) => each.id == this.props.route?.params?.type
    );
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  generateFullFilter = () => {
    //need to create an object without the reference.
    let filter = Object.create(this.state.searchFilter);
    if (filter) {
      filter.status = this.state.selectedTab?.id;
      filter.type = this.props.route?.params?.type;
    } else {
      filter = {
        status: this.state.selectedTab?.id,
        type: this.props.route?.params?.type,
      };
    }

    return filter;
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addAccessModalRef}>
        <NtPinManagementNewForm
          type={this.handleTypeFetch()}
          onCancel={() => {
            this.addAccessModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.state.selectedTab = this.tabs[0];
            this.setState({
              selectedTab: this.state.selectedTab,
              searchText: "",
              searchFilter: null,
            });

            this.page = 1;
            this.handleDataFetch();
            this.addAccessModalRef.current?.dismiss();
            showToast("Success", "Successfully created a new pin");
          }}
        />
      </NtModal>
    );
  };

  renderUploadForm = () => {
    return (
      <NtModal ref={this.uploadAccessModalRef}>
        <NtPinmanagementUploadForm
          onCancel={() => {
            this.uploadAccessModalRef.current?.dismiss();
          }}
          onUploaded={() => {
            this.page = 1;
            this.handleDataFetch();
            this.uploadAccessModalRef.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  renderArchiveCabinDataAllForm = () => {
    return (
      <NtModal ref={this.archiveCabinDataModalRef}>
        <NtArchiveCabinDataAllForm
          onDone={() => {
            this.archiveCabinDataModalRef?.current?.dismiss();
          }}
          onCancel={() => {
            this.archiveCabinDataModalRef?.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  renderAdvanceSearchForm = () => {
    return (
      <NtModal ref={this.advanceSearchRef}>
        <NtPinmanagementAdvanceSearchPicker
          type={this.props.route?.params?.type}
          filter={this.state.searchFilter}
          onCancel={() => {
            this.advanceSearchRef.current?.dismiss();
          }}
          onFilter={(filter) => {
            this.state.searchFilter = filter;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
          onFilterClear={() => {
            this.state.searchFilter = null;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
        />
      </NtModal>
    );
  };

  renderTabs = () => {
    return (
      <NtTabController
        containerStyle={{ marginTop: 20, marginBottom: 10 }}
        options={this.tabs}
        selection={this.state.selectedTab}
        onPress={(item) => {
          if (item?.id == this.state.selectedTab?.id) {
            //no need to reload since we are not changing tabs
            return;
          }

          this.state.selectedTab = item;
          this.setState({
            selectedTab: this.state.selectedTab,
            searchText: "",
            data: [],
          });
          this.page = 1;
          this.handleDataFetch();
        }}
      />
    );
  };

  renderRow = (item, index) => {
    return (
      <NtPinManagementItem
        key={item.id}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast("Success", "Successfully updated the pin");
        }}
        onReset={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast("Success", "Successfully Reset the cabin");
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={`${this.handleTypeFetch()?.title || "Pin"} Management`}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        searchFilterCount={
          this.state.searchFilter
            ? Object.keys(this.state.searchFilter)?.length
            : 0
        }
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        onSearchFilterPress={() => {
          this.advanceSearchRef?.current?.show();
        }}
        maxWidth={"100%"}
      >
        {this.renderTabs()}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
          <NtPageActionButton
            icon={"arrow-up"}
            onPress={() => {
              this.uploadAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 10 }}
          />

          {this.props.route?.params?.type == "cabin" && (
            <NtPageActionButton
              icon={"archive"}
              onPress={() => {
                this.archiveCabinDataModalRef.current?.show();
              }}
              title={"Reset Billing Period"}
              containerStyle={{
                marginLeft: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            />
          )}
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={pincsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            overideColumnName={[
              {
                id: "pin",
                title:
                  this.props.route?.params?.type == "cabin"
                    ? this.handleTypeFetch()?.title
                    : "Pin",
              },
            ]}
            exportFileName={"pin"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderUploadForm()}
        {this.renderArchiveCabinDataAllForm()}
        {this.renderAdvanceSearchForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default PinManagementController;
