import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import {
  parseDisplayDate,
  parseSimpleDisplayDate,
} from "../../common/utils/ControllerUtils";
import cronstrue from "cronstrue";
import NtModal from "../../components/NtModal";
import NtReportScheduleUpdateForm from "./NtReportScheduleUpdateForm";
import NtListRow from "../../components/NtListRow";
import NtEmailItem from "../../components/NtEmailItem";

function NtReportScheduleItem({ item, columns, onUpdate }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtReportScheduleUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "creationdate") {
            // this is for pin.....
            return parseSimpleDisplayDate(item[column]);
          } else if (column == "receiver") {
            const emails = item[column]?.split(",");
            return (
              <View
                style={{
                  alignItems: "flex-start",
                  justifyContent: "center",
                  marginLeft: -20,
                }}
                key={column}
              >
                {emails?.map((each, index) => (
                  <NtEmailItem
                    containerStyle={{ marginBottom: 6 }}
                    key={each}
                    email={each}
                  />
                ))}
              </View>
            );
          } else if (column == "schedule") {
            return cronstrue.toString(item[column]);
          } else if (column == "lastrun") {
            return item[column] ? parseDisplayDate(item[column]) : "N/A";
          } else if (column == "use_local_timezone") {
            if (item[column] == 0) {
              return "UTC";
            }
            return "Local Time";
          }
        }}
        onActionPress={() => {
          updateModalRef?.current?.show();
        }}
      />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default NtReportScheduleItem;
