import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import { sleep } from "../../common/utils/ControllerUtils";
import { createPin } from "../../api/billing";

const handleCreateAll = async (results, environment) => {
  const success = [];
  const failure = [];

  Logger(
    "Done reading the .csv... will start processing now..",
    results?.data?.length
  );

  for (let x = 0; x < results?.data?.length; x++) {
    let each = results?.data[x];

    const params = {
      pin: each.pin || each.cabin,
      name: each.name,
      date: each.expirationdate,
      type: each.type,
      tariff: each.tariff,
      status: 1,
    };

    const response = await createPin(params);

    Logger("Pin upload POST", response, x);

    if (response.ok) {
      success.push(each);
    } else {
      failure.push({
        request: each,
        status: response.status,
        error: response.data,
      });
    }
  }

  return {
    ok: true,
    success: success,
    failure: failure,
    total: results.data.length,
  };
};

export const handlePinUploadCreate = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function async(results) {
        Logger("Done reading the .csv... will start processing now..");

        const response = handleCreateAll(results, environment);
        resolve(response);
      },
    });
  });
};

export const handlePinUploadReset = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        let data = [];
        results.data.map(async (each, index) => {
          data.push(each.cabin);
        });

        resolve({ data: data });
      },
    });
  });
};

export const handlePinUploadFailure = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};
