const hardwarecsvcolumndata = [
  {
    id: "mac",
    title: "MAC",
    default: true,
  },

  {
    id: "name",
    title: "Name",
    default: true,
  },
  {
    id: "ext",
    title: "File Extension",
    default: true,
  },
  {
    id: "filepath",
    title: "File Path",
  },
  {
    id: "status",
    title: "Status",
    default: true,
  },
  {
    id: "creationdate",
    title: "Date",
    default: true,
  },
];
export default hardwarecsvcolumndata;
