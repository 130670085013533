import React, { useState } from "react";
import { View, StyleSheet, LogBox } from "react-native";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtText from "../../components/NtText";
import { fetchAutoGeneratedPin } from "../../api/billing";
import Logger from "../../common/utils/Logger";

function NtPinManagementAutoGenerateButton({
  containerStyle,
  type = "admin",
  onPinAvailable,
}) {
  const [loading, setLoading] = useState(false);

  const handleGenerateUnique = async () => {
    setLoading(true);

    const response = await fetchAutoGeneratedPin(type);
    Logger("Autogenerated Pin response", response);
    if (response.ok) {
      if (onPinAvailable) {
        onPinAvailable(response?.data?.number);
      }
    }
    setLoading(false);
  };
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        handleGenerateUnique();
      }}
    >
      <NtText
        style={{
          color: "white",
          fontSize: 12,
          fontWeight: "600",
          marginRight: 4,
        }}
      >
        Generate Pin
      </NtText>
      {!loading && <NtMaterialIcon name="refresh" color="white" size={18} />}
      {loading && <NtActivityIndicator color="white" size={14} />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.blue,
    borderRadius: 20,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 6,
    paddingRight: 6,
  },
});

export default NtPinManagementAutoGenerateButton;
