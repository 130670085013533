import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import { updateRingGroup } from "../../api/ringgroup";
import NtModalAccountPickerField from "./NtModalAccountPickerField";
import NtModalError from "../../components/NtModalError";
import { deleteInboundRoute, updateInboundRoute } from "../../api/inboundroute";

function NtInboundRouteUpdateForm({ item, onCancel, onUpdate, onDelete }) {
  const [name, setName] = useState(item.dialplan_name);
  const [destination, setDestination] = useState(item.dialplan_number);
  const [action, setAction] = useState(item.dialplan_destination_actions);
  const [description, setDescription] = useState(item.dialplan_description);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return name && destination && action;
  };

  const handleXMLUpdate = async () => {};

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      name: name,
      destination: destination,
      action: action,
      description: description,
    };

    const response = await updateInboundRoute(
      context.user?.environment?.domain,
      params,
      item.dialplan_uuid
    );

    Logger("Ringgroup Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    const response = await deleteInboundRoute(
      context.user?.environment?.domain,
      item?.dialplan_uuid
    );

    if (response.ok) {
      if (onDelete) {
        onDelete();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Inbound Route</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update Inbound Route{" "}
        <NtText style={{ fontWeight: "900" }}>
          {item.ring_group_extension}
        </NtText>
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />
      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Destination *"}
        placeholder={"Destination"}
        value={destination}
        setValue={(text) => setDestination(text)}
      />

      <NtModalAccountPickerField
        containerStyle={{ marginTop: 15 }}
        title={"Route Call to"}
        placeholder={"Route Call to"}
        value={action}
        setValue={(text) => setAction(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Description"}
        placeholder={"Description"}
        value={description}
        setValue={(text) => setDescription(text)}
      />

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalActiveButton
            containerStyle={{ backgroundColor: "red" }}
            title={"Delete"}
            icon={"delete"}
            onPress={() => {
              handleDelete();
            }}
            enabled={true}
          />

          <NtModalActiveButton
            containerStyle={{ backgroundColor: "red", marginLeft: 10 }}
            title={"Edit XML"}
            icon={"xml"}
            onPress={() => {
              handleXMLUpdate();
            }}
            enabled={true}
          />
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Update"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtInboundRouteUpdateForm;
