import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import useSelection from "../hooks/useSelection";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";
import NtMaterialIcon from "./NtMaterialIcon";
import NtDevider from "./NtDevider";
import NtModalActiveButton from "./NtModalActiveButton";
import NtModalUnactiveButton from "./NtModalUnactiveButton";
import colors from "../config/colors";

function NtListHeaderColumnPicker({
  onCancel,
  onUpdate,
  selectedColumns,
  columns,
  overideColumnName = [],
}) {
  const selection = useSelection();

  const isRequiredFields = () => {
    return selection.selections?.length > 0;
  };

  useEffect(() => {
    //will set the defaults....
    let defaults = [];
    columns?.map((each) => {
      if (selectedColumns?.find((eachColumn) => each.id == eachColumn.id)) {
        defaults.push(each.id);
      }
    });
    selection.setSelections(defaults);
  }, []);

  const handleUpdate = () => {
    let selectedColumns = [];
    columns?.map((each) => {
      if (selection.isSelected(each.id)) {
        selectedColumns.push(each);
      }
    });

    onUpdate(selectedColumns);
  };

  const handleDefault = () => {
    let defaults = [];
    columns?.map((each) => {
      if (each.default == true) {
        defaults.push(each.id);
      }
    });
    selection.setSelections(defaults);
  };

  const renderRow = (item, index) => {
    const override = overideColumnName?.find((each) => each.id == item.id);

    return (
      <NtTouchableEffect
        key={item.id}
        onPress={() => {
          if (selection.isSelected(item.id)) {
            selection.removeSelection(item.id);
          } else {
            selection.addSelection(item.id);
          }
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtText
            style={[
              styles.rowText,
              {
                flex: 1,
                color: selection.isSelected(item.id)
                  ? colors.darkest
                  : colors.lighGray,
              },
            ]}
          >
            {override?.title || item.title}
          </NtText>
          {selection.isSelected(item.id) && (
            <NtMaterialIcon name="check" size={20} color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Manage Display Columns</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select the columns you wish to display.
      </NtText>

      <ScrollView scrollEnabled={true} contentContainerStyle={{ flexGrow: 1 }}>
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          {columns?.map((each, index) => renderRow(each, index))}
        </View>
      </ScrollView>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalActiveButton
          containerStyle={{}}
          title={"Default"}
          onPress={() => {
            handleDefault();
          }}
          enabled={true}
        />

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Update Selection"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    maxHeight: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtListHeaderColumnPicker;
