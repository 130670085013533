import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import destinationactiondata from "../data/destinationactiondata";

function NtAccountDisplay({ item }) {
  const [action, setAction] = useState(null);

  useEffect(() => {
    const action = destinationactiondata?.find((each) => each.id == item?.name);

    setAction(action);
  }, []);

  if (!action) {
    return null;
  }

  return (
    <View style={styles.container}>
      <NtMaterialIcon name={action?.icon} color="white" size={15} />
      <NtText
        style={{
          color: "white",
          fontSize: 12,
          fontWeight: "600",
          marginLeft: 6,
        }}
      >
        {item?.value}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: colors.blue,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3,
    paddingBottom: 3,
  },
});

export default NtAccountDisplay;
