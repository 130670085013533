const cdrstatisticcolumndata = [
  {
    id: "type",
    title: "Type",
    default: true,
  },
  {
    id: "calls_total",
    title: "Total Calls",
    default: true,
  },
  {
    id: "calls_current",
    title: "Current Calls",
    default: true,
  },
  {
    id: "calls_answered",
    title: "Answered Calls",
    default: true,
  },
  {
    id: "free_minutes_given",
    title: "Free Minutes Given",
    default: true,
  },
  {
    id: "billed_minutes",
    title: "Minutes Billed",
    default: true,
  },
  {
    id: "sessioncost",
    title: "Total Cost",
    default: true,
  },
  {
    id: "sessionprice",
    title: "Total Billed",
    default: true,
  },
];
export default cdrstatisticcolumndata;
