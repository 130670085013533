import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BasePinController from "./BasePinController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtPageActionButton from "../../components/NtPageActionButton";
import callingratescolumndata from "../data/callingratescolumndata";
import { fetchCallingRates, fetchTariffs } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import NtListHeader from "../../components/NtListHeader";
import {
  asyncStorageSetData,
  parseDecimalPlaces,
  parseSimpleDisplayDate,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtCallingRatesItem from "../components/NtCallingRatesItem";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtCallingRatesStatItem from "../components/NtCallingRatesStatItem";
import NtModal from "../../components/NtModal";
import NtCallingRatesTariffNewForm from "../components/NtCallingRatesTariffNewForm";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtListEmpty from "../../components/NtListEmpty";
import NtTabController from "../../components/NtTabController";

class CallingRateController extends BasePinController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    tariffData: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
    selectedTab: null,
  };

  column_location = "@callingrates_column_storage";
  page = 1;

  addTariffModalRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      callingratescolumndata.filter((each) => each.default == true)
    );

    this.handleTariffFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.moduleCallingCards);
  };

  handleTariffFetch = async () => {
    Logger("Fetching Tariffs data");

    this.setState({ loading: true });

    const response = await fetchTariffs(null);
    Logger("Data response Tariff GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.data?.length);

      //will convert to title and ID
      response.data = response.data?.data?.map((each) => {
        return {
          title: each.id,
          id: each.id,
          default_cost: each?.default_cost,
          default_price: each?.default_price,
          description: each?.description,
          initblock: each?.initblock,
        };
      });

      this.state.selectedTab = response.data[0];
      this.setState({
        tariffData: response.data,
        selectedTab: response.data[0],
      });

      this.handleDataFetch();
    }

    this.setState({ loading: false });
  };

  handleDataFetch = async () => {
    Logger("Fetching Calling Rates data");

    this.setState({ loading: true });

    const response = await fetchCallingRates(
      this.state.selectedTab?.id,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      this.state.searchFilter
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.data?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.data,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.data),
        });
      }
    }

    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Calling Rates export data");

    const response = await fetchCallingRates(
      this.state.selectedTab?.id,
      this.page,
      10000000,
      this.state.searchText,
      this.state.searchFilter
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      return response.data.data;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "creationdate") {
      return parseSimpleDisplayDate(item[column]);
    } else if (column == "status") {
      return item[column] == 1 ? "Active" : "Inactive";
    } else if (column == "price" || column == "cost") {
      return `$${parseDecimalPlaces(item[column])}`;
    }

    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addTariffModalRef}>
        <NtCallingRatesTariffNewForm
          tariff={this.state.selectedTab?.id}
          onCancel={() => {
            this.addTariffModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.page = 1;
            this.handleDataFetch();
            this.addTariffModalRef.current?.dismiss();
            showToast("Success", "Successfully created a new special rate");
          }}
        />
      </NtModal>
    );
  };

  renderTabs = () => {
    return (
      <NtTabController
        containerStyle={{ marginTop: 20, marginBottom: 10 }}
        options={this.state.tariffData}
        selection={this.state.selectedTab}
        onPress={(item) => {
          if (item?.id == this.state.selectedTab?.id) {
            //no need to reload since we are not changing tabs
            return;
          }

          this.state.selectedTab = item;
          this.setState({
            selectedTab: this.state.selectedTab,
            searchText: "",
            data: [],
          });
          this.page = 1;
          this.handleDataFetch();
        }}
      />
    );
  };

  renderRow = (item, index) => {
    return (
      <NtCallingRatesItem
        key={item?.id}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast("Success", "Successfully updated a special rate");
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Calling Rates"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        {this.state.tariffData?.length > 0 && (
          <View>
            {this.renderTabs()}
            <NtText
              style={{
                color: colors.lighGray,
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              {this.state.selectedTab?.description}
            </NtText>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <NtCallingRatesStatItem
                title={"Default Cost"}
                value={`$${parseDecimalPlaces(
                  this.state.selectedTab?.default_cost
                )}`}
              />

              <NtCallingRatesStatItem
                containerStyle={{ marginLeft: 15 }}
                title={"Default Price"}
                value={`$${parseDecimalPlaces(
                  this.state.selectedTab?.default_price
                )}`}
              />

              <NtCallingRatesStatItem
                containerStyle={{ marginLeft: 15 }}
                title={"Free Minutes"}
                showSlashMin={false}
                value={this.state.selectedTab?.initblock / 60}
              />
            </View>
          </View>
        )}

        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
        >
          <NtMaterialIcon name="chart-bar" color={colors.blue} />
          <NtText style={[styles.subtitle, { marginLeft: 6 }]}>
            Special Rates
          </NtText>
        </View>

        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addTariffModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={callingratescolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"call-rates"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />

          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  subtitle: {
    fontSize: 20,
    fontWeight: "700",
    color: colors.blue,
  },
});

export default CallingRateController;
