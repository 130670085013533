const calldirectiondata = [
  {
    id: "outbound",
    title: "Outbound",
  },
  {
    id: "inbound",
    title: "Inbound",
  },
  {
    id: "system",
    title: "System",
  },
];
export default calldirectiondata;
