import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtPinManagementResetconfirmForm({ onCancel, onConfirm }) {
  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name="alert-outline" />
        <NtText style={[styles.title, { marginLeft: 6 }]}>Confirm Reset</NtText>
      </View>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Are you sure you want to reset this PIN? This action will delete all
        Call Detail Records and will reset the credit to its initial balance
      </NtText>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ backgroundColor: "red", marginLeft: 15 }}
          title={"Reset"}
          onPress={onConfirm}
          icon={"alert-outline"}
          enabled={true}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinManagementResetconfirmForm;
