import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import NtDevider from "./NtDevider";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";
import NtTouchableEffect from "./NtTouchableEffect";
import NtModal from "./NtModal";
import NtListHeaderColumnPicker from "./NtListHeaderColumnPicker";
import NtListHeaderExportModal from "./NtListHeaderExportModal";

function NtListHeader({
  containerStyle,
  headerContainerStyle,
  headerTextStyle,
  selectedColumns,
  columns,
  onColumnUpdate,
  visible = true,
  overideColumnName = [],

  //export
  exportFileName,
  exportDataParser,
  exportFetchDataAsync,
}) {
  const fieldsSelectRef = useRef();
  const exportModalRef = useRef();

  const renderRow = (item) => {
    const override = overideColumnName?.find((each) => each.id == item.id);

    return (
      <View
        style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}
        key={item.title}
      >
        <NtText style={[styles.text, headerTextStyle]}>
          {override?.title || item.title}
        </NtText>
      </View>
    );
  };

  const renderFieldsSelectModal = () => {
    return (
      <NtModal ref={fieldsSelectRef}>
        <NtListHeaderColumnPicker
          onCancel={() => {
            fieldsSelectRef.current?.dismiss();
          }}
          overideColumnName={overideColumnName}
          selectedColumns={selectedColumns}
          columns={columns}
          onUpdate={(selected) => {
            if (onColumnUpdate) {
              onColumnUpdate(selected);
            }
            fieldsSelectRef.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  const renderExportModal = () => {
    return (
      <NtModal ref={exportModalRef}>
        <NtListHeaderExportModal
          onCancel={() => {
            exportModalRef.current?.dismiss();
          }}
          overideColumnName={overideColumnName}
          selectedColumns={selectedColumns}
          columns={columns}
          onExported={() => {
            exportModalRef.current?.dismiss();
          }}
          exportFileName={exportFileName}
          exportDataParser={exportDataParser}
          exportFetchDataAsync={exportFetchDataAsync}
        />
      </NtModal>
    );
  };

  const renderButton = (icon, title, onPress, containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          },
          containerStyle,
        ]}
        onPress={onPress}
      >
        <NtMaterialIcon name={icon} color={colors.blue} size={16} />
        <NtText
          style={{
            color: colors.blue,
            fontWeight: "600",
            fontSize: 12,
            fontStyle: "italic",
            marginLeft: 4,
          }}
        >
          {title}
        </NtText>
      </NtTouchableEffect>
    );
  };

  if (!visible) {
    return true;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          alignItems: "flex-end",
          marginBottom: 10,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {exportFileName &&
            exportFetchDataAsync &&
            renderButton(
              "download",
              "Export Data",
              () => {
                exportModalRef.current?.show();
              },
              {
                marginRight: 25,
              }
            )}

          {columns &&
            renderButton("cog", "Manage Columns", () => {
              fieldsSelectRef.current?.show();
            })}
        </View>
      </View>

      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          },
          headerContainerStyle,
        ]}
      >
        {selectedColumns?.map((each) => renderRow(each))}
      </View>
      <NtDevider />
      {renderFieldsSelectModal()}
      {renderExportModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    fontWeight: "900",
    color: colors.almostBlack,
  },
});

export default NtListHeader;
