import React, { act } from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import colors from "../../config/colors";
import BaseController from "../../common/base/BaseController";
import { fetchStatistics } from "../../api/dashboard";
import Logger from "../../common/utils/Logger";
import NtSection from "../components/NtSection";
import pbxstatisticcolumndata from "../data/pbxstatisticcolumndata";
import pinstatisticcolumndata from "../data/pinstatisticcolumndata";
import cdrstatisticcolumndata from "../data/cdrstatisticcolumndata";
import NtText from "../../components/NtText";
import NtListHeader from "../../components/NtListHeader";
import NtListRow from "../../components/NtListRow";
import { parseDecimalPlaces } from "../../common/utils/ControllerUtils";

class DashboardController extends BaseController {
  state = {
    statistics: null,
    loading: false,
  };

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    this.setState({ loading: true });
    const response = await fetchStatistics(
      this.context.user?.environment?.domain
    );

    Logger("Fetched statistics data", response);

    if (response.ok) {
      this.setState({ statistics: response.data });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Dashboard"}
        showFooter={true}
        maxWidth={"100%"}
      >
        <NtSection title={"PBX"} containerStyle={{ marginTop: 35 }}>
          {this.state.statistics?.pbx && (
            <View>
              <NtListHeader
                selectedColumns={pbxstatisticcolumndata}
                headerContainerStyle={{
                  backgroundColor: colors.blue,
                  paddingLeft: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                headerTextStyle={{ color: "white", fontWeight: "600" }}
              />

              <NtListRow
                rowContainerStyle={{ paddingLeft: 5 }}
                item={this.state.statistics?.pbx}
                columns={pbxstatisticcolumndata}
                renderCustom={(item, column) => {}}
              />
            </View>
          )}
        </NtSection>

        <NtSection title={"Calling Cards"} containerStyle={{ marginTop: 35 }}>
          <View>
            <NtListHeader
              selectedColumns={pinstatisticcolumndata}
              headerContainerStyle={{
                backgroundColor: colors.blue,
                paddingLeft: 5,
                paddingTop: 5,
                paddingBottom: 5,
              }}
              headerTextStyle={{ color: "white", fontWeight: "600" }}
            />
            {this.state.statistics?.callingcards
              ?.filter((each) => each.type)
              ?.map((each, index) => (
                <NtListRow
                  rowContainerStyle={{ paddingLeft: 5 }}
                  key={each.type}
                  item={each}
                  columns={pinstatisticcolumndata}
                  renderCustom={(item, column) => {
                    let data = item[column];

                    if (column == "type") {
                      if (item.type == "total") {
                        data =
                          item.type[0]?.toUpperCase() + item.type?.slice(1);
                      }
                    } else if (column == "status" && item.type != "total") {
                      data = item[column] == 1 ? "Active" : "Inactive";
                    }

                    if (item.type == "total") {
                      return (
                        <NtText
                          style={{
                            color: colors.almostBlack,
                            fontWeight: "900",
                          }}
                        >
                          {data}
                        </NtText>
                      );
                    } else {
                      return data;
                    }
                  }}
                />
              ))}
          </View>
        </NtSection>

        <NtSection
          title={"Calling Cards - CDRs"}
          containerStyle={{ marginTop: 35 }}
        >
          <View>
            <NtListHeader
              selectedColumns={cdrstatisticcolumndata}
              headerContainerStyle={{
                backgroundColor: colors.blue,
                paddingLeft: 5,
                paddingTop: 5,
                paddingBottom: 5,
              }}
              headerTextStyle={{ color: "white", fontWeight: "600" }}
            />
            {this.state.statistics?.callingcards?.map((each, index) => (
              <NtListRow
                rowContainerStyle={{ paddingLeft: 5 }}
                key={each.type}
                item={each}
                columns={cdrstatisticcolumndata}
                renderCustom={(item, column) => {
                  let data = item[column];

                  if (column == "free_minutes_given") {
                    data = (item.used_minutes - item.billed_minutes).toString();
                  } else if (
                    column == "sessioncost" ||
                    column == "sessionprice"
                  ) {
                    data = `$${parseDecimalPlaces(item[column])}`;
                  } else if (column == "type") {
                    if (item.type == "total") {
                      data = item.type[0]?.toUpperCase() + item.type?.slice(1);
                    }
                  }

                  if (item.type == "total") {
                    return (
                      <NtText
                        style={{ color: colors.almostBlack, fontWeight: "900" }}
                      >
                        {data}
                      </NtText>
                    );
                  } else {
                    return data;
                  }
                }}
              />
            ))}
          </View>
        </NtSection>

        <NtSection title={"Messaging"} containerStyle={{ marginTop: 35 }}>
          <View>
            <NtText style={[styles.messages, { marginLeft: 4, marginTop: 8 }]}>
              Messages: {this.state.statistics?.messaging?.messages}
            </NtText>
          </View>
        </NtSection>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "800",
    color: colors.lighGray,
  },
  messages: {
    fontSize: 14,
    fontWeight: "800",
    color: colors.lighGray,
  },
});

export default DashboardController;
