import React from "react";
import { View, StyleSheet, Image } from "react-native";
import AppNavigator from "../../navigation/AppNavigator";
import routes from "../../navigation/routes";
import NtNavigationBar from "../components/NtNavigationBar";
import Logger from "../../common/utils/Logger";
import RootController from "./RootController";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import colors from "../../config/colors";
import { navigationRef } from "../../../App";
import settings from "../../config/settings";

class RootAppController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
    sidebarCollapsed: this.context.user?.ui
      ? this.context.user?.ui?.sidebarcollapsed
      : true,
  };

  isOmniChannelModuleEnabled = () => {
    return (
      settings.environmentCarnivalUK != this.context.user?.environment?.code
    );
  };

  isCallingCardModuleEnable = () => {
    return true;
  };

  isPBXModuleEnabled = () => {
    return (
      settings.environmentCarnivalUK != this.context.user?.environment?.code
    );
  };

  options = [
    {
      icon: "gauge",
      title: "Dashboard",
      action: () => {
        navigationRef.current.navigate(routes.dashboard);
      },
      isSelected: () => {
        return (
          navigationRef.current?.getCurrentRoute()?.name == routes.dashboard
        );
      },
    },

    ...(this.isOmniChannelModuleEnabled()
      ? [
          {
            icon: "gamepad-circle-outline",
            title: "Omni Channel",
            action: () => {
              navigationRef.current.navigate(routes.messagev2);
            },
            isSelected: () => {
              return (
                navigationRef.current?.getCurrentRoute()?.name ==
                routes.messagev2
              );
            },
          },
        ]
      : []),

    ...(this.isCallingCardModuleEnable()
      ? [
          {
            icon: "dialpad",
            title: "Calling Cards",
            isSelected: () => {
              let response = false;
              this.options
                ?.find((each) => each.title == "Calling Cards")
                ?.items?.map((each) => {
                  response = response || each.isSelected();
                });

              return response;
            },
            items: [
              {
                icon: "pin",
                title: "Management",
                isSelected: () => {
                  let response = false;
                  this.options
                    ?.find((each) => each.title == "Calling Cards")
                    ?.items?.find((each) => each.title == "Management")
                    ?.items?.map((each) => {
                      response = response || each.isSelected();
                    });

                  return response;
                },
                items: [
                  {
                    title: "Cabins",
                    action: () => {
                      navigationRef.current.navigate(routes.pinmanagement, {
                        type: "cabin",
                      });
                    },
                    isSelected: () => {
                      return (
                        navigationRef.current?.getCurrentRoute()?.name ==
                          routes.pinmanagement &&
                        navigationRef.current.getCurrentRoute()?.params?.type ==
                          "cabin"
                      );
                    },
                  },
                  {
                    title: "Admins",
                    action: () => {
                      navigationRef.current.navigate(routes.pinmanagement, {
                        type: "admin",
                      });
                    },
                    isSelected: () => {
                      return (
                        navigationRef.current?.getCurrentRoute()?.name ==
                          routes.pinmanagement &&
                        navigationRef.current.getCurrentRoute()?.params?.type ==
                          "admin"
                      );
                    },
                  },
                ],
              },
              {
                icon: "history",
                title: "CDRs",
                isSelected: () => {
                  let response = false;
                  this.options
                    ?.find((each) => each.title == "Calling Cards")
                    ?.items?.find((each) => each.title == "CDRs")
                    ?.items?.map((each) => {
                      response = response || each.isSelected();
                    });

                  return response;
                },
                items: [
                  {
                    title: "Cabins",
                    action: () => {
                      navigationRef.current.navigate(routes.pincdr, {
                        type: "cabin",
                      });
                    },
                    isSelected: () => {
                      return (
                        navigationRef.current?.getCurrentRoute()?.name ==
                          routes.pincdr &&
                        navigationRef.current.getCurrentRoute()?.params?.type ==
                          "cabin"
                      );
                    },
                  },
                  {
                    title: "Admins",
                    action: () => {
                      navigationRef.current.navigate(routes.pincdr, {
                        type: "admin",
                      });
                    },
                    isSelected: () => {
                      return (
                        navigationRef.current?.getCurrentRoute()?.name ==
                          routes.pincdr &&
                        navigationRef.current.getCurrentRoute()?.params?.type ==
                          "admin"
                      );
                    },
                  },
                ],
              },
              {
                icon: "chart-bar",
                title: "Calling Rates",
                action: () => {
                  navigationRef.current.navigate(routes.callingrate);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.callingrate
                  );
                },
              },
              {
                icon: "archive",
                title: "Report Schedule",
                action: () => {
                  navigationRef.current.navigate(routes.reportschedule);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.reportschedule
                  );
                },
              },
            ],
          },
        ]
      : []),

    ...(this.isPBXModuleEnabled()
      ? [
          {
            icon: "deskphone",
            title: "PBX",
            isSelected: () => {
              let response = false;
              this.options
                ?.find((each) => each.title == "PBX")
                ?.items?.map((each) => {
                  response = response || each.isSelected();
                });

              return response;
            },
            items: [
              {
                icon: "phone",
                title: "Extensions",
                description: "View extension report",
                action: () => {
                  navigationRef.current.navigate(routes.extension);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.extension
                  );
                },
              },
              {
                icon: "account-group",
                title: "Ring Groups",
                description: "View extension report",
                action: () => {
                  navigationRef.current.navigate(routes.ringgroup);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.ringgroup
                  );
                },
              },
              {
                icon: "phone-incoming",
                title: "Inbound Route",
                description: "View extension report",
                action: () => {
                  navigationRef.current.navigate(routes.inboundroute);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.inboundroute
                  );
                },
              },
              {
                icon: "voicemail",
                title: "Voicemail",
                description: "View extension report",
                action: () => {
                  navigationRef.current.navigate(routes.voicemail);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.voicemail
                  );
                },
              },
              {
                icon: "dialpad",
                title: "App Access",
                description: "Configure PIN Access for apps",
                action: () => {
                  navigationRef.current.navigate(routes.appaccess);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.appaccess
                  );
                },
              },
              {
                icon: "server",
                title: "Gateway Report",
                description: "View gateway report",
                action: () => {
                  navigationRef.current.navigate(routes.gatewayreport);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.gatewayreport
                  );
                },
              },
              {
                icon: "chip",
                title: "Hardware",
                description: "View gateway report",
                action: () => {
                  navigationRef.current.navigate(routes.hardware);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.hardware
                  );
                },
              },
              {
                icon: "asterisk",
                title: "Feature Codes",
                description: "Feature code list",
                action: () => {
                  navigationRef.current.navigate(routes.featurecode);
                },
                isSelected: () => {
                  return (
                    navigationRef.current?.getCurrentRoute()?.name ==
                    routes.featurecode
                  );
                },
              },
            ],
          },
        ]
      : []),
  ];

  pbxItems = [];

  componentDidMount() {
    super.componentDidMount();
  }

  showSidePannel = () => {
    return (
      this.state.currentController !== routes.notfound &&
      this.state.currentController !== routes.marketing &&
      this.state.currentController !== routes.health &&
      this.state.currentController !== routes.guestapp &&
      this.state.currentController !== routes.crewapp &&
      this.state.currentController !== routes.pbxsystem &&
      this.state.currentController !== routes.hospitalitypbx &&
      this.state.currentController !== routes.starlink &&
      this.state.currentController !== routes.gov
    );
  };

  isPBXSelected = () => {
    let response = false;
    this.pbxItems.map((each) => {
      response = response || each.isSelected();
    });

    return response;
  };

  renderMenu = (
    item,
    subMenuStyle = {},
    menuStyle = {},
    submenuIconSize = 24,
    menuIconSize = 18
  ) => {
    if (item?.items) {
      return (
        <SubMenu
          key={item.title}
          icon={
            <NtMaterialIcon
              name={item.icon}
              color={colors.blue}
              size={submenuIconSize}
            />
          }
          style={{
            ...styles.menu,
            ...subMenuStyle,
            backgroundColor: item.isSelected() ? colors.lighestGray : null,
          }}
          label={item.title}
        >
          {item?.items?.map((each) =>
            this.renderMenu(
              each,
              { fontSize: 12, fontWeight: "600" },
              { fontSize: 12, fontWeight: "500" },
              18
            )
          )}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem
          key={item.title}
          icon={
            item.icon ? (
              <NtMaterialIcon
                name={item.icon}
                color={colors.blue}
                size={menuIconSize}
              />
            ) : null
          }
          onClick={() => {
            item.action();
          }}
          style={{
            ...styles.menu,
            ...menuStyle,
            backgroundColor: item?.isSelected() ? colors.lighestGray : null,
          }}
        >
          {item.title}
        </MenuItem>
      );
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          {this.showSidePannel() && (
            <Sidebar style={{}} collapsed={this.state.sidebarCollapsed}>
              <View style={{ height: "100%" }}>
                {/* header */}
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    paddingTop: 10,
                  }}
                >
                  <Image
                    style={{ width: 35, height: 35, resizeMode: "contain" }}
                    source={require("../../assets/images/favicon-big-blue.png")}
                  />
                  {!this.state.sidebarCollapsed && (
                    <View style={{ marginLeft: 10 }}>
                      <NtText
                        style={{
                          fontSize: 15,
                          fontWeight: "800",
                          color: colors.almostBlack,
                        }}
                      >
                        Hi
                      </NtText>
                      <NtText
                        style={{
                          fontSize: 13,
                          fontWeight: "600",
                          color: colors.almostBlack,
                        }}
                      >
                        {this.context?.user?.username}
                      </NtText>
                    </View>
                  )}
                </View>

                <Menu style={{ marginTop: 50 }} closeOnClick={true}>
                  {this.options.map((each) => this.renderMenu(each))}
                </Menu>

                <View style={{ flex: 1 }} />

                <View style={{ paddingBottom: 30 }}>
                  <Menu>
                    <MenuItem
                      icon={<NtMaterialIcon name={"logout"} color={"red"} />}
                      onClick={() => {
                        if (this.context.user.authenticated) {
                          this.context.updateUser({
                            ...this.context.user,
                            authenticated: false,
                          });
                        }

                        setTimeout(() => {
                          navigationRef.current.navigate(routes.auth);
                        }, 500);
                      }}
                      style={{ ...styles.menu, color: "red" }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </View>
              </View>
            </Sidebar>
          )}

          <View style={{ flex: 1 }}>
            {this.showSidePannel() && (
              <NtNavigationBar
                sidebarCollapsed={this.state.sidebarCollapsed}
                onSidebarToggle={() => {
                  this.state.sidebarCollapsed = !this.state.sidebarCollapsed;

                  this.context.updateUser({
                    ...this.context.user,
                    ui: { sidebarcollapsed: this.state.sidebarCollapsed },
                  });

                  this.setState({
                    sidebarCollapsed: this.state.sidebarCollapsed,
                  });
                }}
              />
            )}
            <AppNavigator
              onControllerChanged={(controller) => {
                Logger("Current controller: ", controller);
                this.setState({ currentController: controller });
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
  menu: {
    fontFamily: "Helvetica",
    color: colors.almostBlack,
    fontWeight: "600",
    fontSize: 13,
  },
});

export default RootAppController;
