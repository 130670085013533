import { channel } from "expo-updates";
import { messagechanneltypes } from "../data/messagechanneltypes";
import RecordItem from "./Message/RecordItem";
import Logger from "../../common/utils/Logger";

export const displayNameForRecord = (address, directory, me = null) => {
  Logger("------------- will try to find the name for : ", address);
  const record = new RecordItem(address);
  let item = null;
  let response = "";
  if (record.isGroup()) {
    let maxName = 3;
    let counter = 0;
    let participants = address?.split(":");
    for (let x = 0; x < participants?.length; x++) {
      if (!participants[x]?.includes(me)) {
        if (counter == maxName) {
          break;
        }

        const parsed = participants[x]?.split("@")[0];
        item = directory?.find((each) => each.number == parsed);
        if (item) {
          response += item?.name;
        } else {
          response += parsed;
        }

        response += ",";
        counter++;
      }
    }

    if (participants?.length - 1 > maxName) {
      response += "+" + (participants?.length - maxName - 1);
    } else {
      response = response?.substring(0, response?.length - 1);
    }
  } else {
    const parsed = record.getAddress()?.split("@")[0];
    item = directory?.find(
      (each) => each.number == parsed || each.externalNumber == parsed
    );

    if (item) {
      response = item.name;
    } else {
      response = parsed;
    }
  }

  Logger("------------- will try to find the name for : ", {
    name: response,
    directory: item,
  });
  return { name: response, directory: item };
};

export const parseChannelFromAddress = (address) => {
  if (address) {
    let domain = address?.split("@")[1];

    return (
      messagechanneltypes.find((each) => each.service == domain) ||
      messagechanneltypes[0]
    );
  }
};
