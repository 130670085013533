import Logger from "../common/utils/Logger";
import client from "./client";

export const fetchInboundRoutes = (domain, page, pageSize, search) => {
  let endpoint = `/v2/pbx/domain/${domain}/dialplan/route/inbound?page=${page}&pagesize=${pageSize}&withExtraInfo=true`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  return client.get(endpoint);
};

export const createInboundRoute = (domain, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/dialplan/route/inbound`;

  const body = {
    name: params?.name,
    route: {
      number: params?.destination,
    },
    action: {
      name: params?.action?.name,
      value: params?.action?.value,
    },
  };

  if (params?.description) {
    body.dialplan = { dialplan_description: params?.description };
  }

  return client.post(endpoint, body);
};

export const updateInboundRoute = (domain, params, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/dialplan/route/inbound/${id}`;

  const body = {
    name: params?.name,
    route: {
      number: params?.destination,
    },
    action: {
      name: params?.action?.name,
      value: params?.action?.value,
    },
  };

  if (params?.description || params?.xml) {
    body.dialplan = {};
  }

  if (params?.description) {
    body.dialplan.dialplan_description = params?.description;
  }

  if (params?.xml) {
    body.dialplan.dialplan_xml = params?.xml;
  }

  return client.patch(endpoint, body);
};

export const deleteInboundRoute = (domain, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/dialplan/route/inbound/${id}`;

  return client.delete(endpoint);
};
