import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtListRow from "../../components/NtListRow";
import NtModal from "../../components/NtModal";
import NtRingGroupUpdateForm from "./NtRingGroupUpdateForm";
import NtInboundRouteUpdateForm from "./NtInboundRouteUpdateForm";
import Logger from "../../common/utils/Logger";
import NtText from "../../components/NtText";
import destinationactiondata from "../data/destinationactiondata";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtAccountDisplay from "./NtAccountDisplay";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtVoicemailUpdateForm from "./NtVoicemailUpdateForm";

function NtVoicemailItemItem({ item, columns, onPress, onUpdate, onDelete }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtVoicemailUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
          onDelete={() => {
            updateModalRef.current?.dismiss();
            if (onDelete) {
              onDelete();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <NtTouchableEffect style={styles.container} onPress={onPress}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "voicemail_enabled") {
            return item[column] == "true" ? "Enabled" : "Disabled";
          }
        }}
        onActionPress={() => {
          updateModalRef.current?.show();
        }}
      />
      {renderUpdateForm()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtVoicemailItemItem;
