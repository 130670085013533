import React, { useState, useEffect, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { createPin, fetchTariffs } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import pintypesdata from "../data/pintypesdata";
import { parseError } from "../../api/client";
import NtTabController from "../../components/NtTabController";
import NtModalError from "../../components/NtModalError";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtPinManagementAutoGenerateButton from "./NtPinManagementAutoGenerateButton";

function NtPinManagementNewForm({ type, onCancel, onCreate }) {
  const [pin, setPin] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [credit, setCredit] = useState("");
  const [tariffs, setTariffs] = useState([]);
  const [tariff, setTariff] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRandomPin, setLoadingRandomPin] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    if (type?.id == "cabin") {
      return name && pin && type && tariff;
    } else if (type?.id == "admin") {
      return name && pin && type && date && tariff;
    }

    return name && pin && credit && type && date;
  };

  const handleCreate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      pin: pin,
      name: name,
      date: date,
      type: type?.id,
      tariff: tariff?.id,
      credit: credit,
    };

    const response = await createPin(params);

    Logger("AppAccess response POST", response, response.status);

    if (response.ok) {
      if (onCreate) {
        onCreate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleAutogenerate = async () => {
    setLoadingRandomPin(true);

    setLoadingRandomPin(false);
  };

  const handleTariffFetch = async () => {
    setLoading(true);

    const response = await fetchTariffs(type?.id, 1, 10000);
    Logger("Taffifs fetch response", response);
    if (response.ok) {
      const data = response.data?.data?.map((each) => {
        return {
          id: each.id,
          title: each.id,
        };
      });
      setTariffs(data);

      if (data?.length == 1) {
        setTariff(data[0]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    handleTariffFetch();
  }, []);

  const renderTariff = (containerStyle) => {
    if (tariffs?.length <= 1) {
      return null;
    }
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Tariffs *
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={tariffs}
          selection={tariff}
          onPress={(item) => {
            setTariff(item);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>
        Add New {type?.title} {type?.id != "cabin" ? "Pin" : ""}
      </NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will make calling possible for those using calling cards
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={type?.id == "cabin" ? "Cabin *" : "Pin *"}
        placeholder={type?.id == "cabin" ? "Cabin" : "Pin"}
        value={pin}
        renderAction={
          type?.id == "cabin" ? null : (
            <NtPinManagementAutoGenerateButton
              containerStyle={{ marginRight: -6 }}
              type={type?.id}
              onPinAvailable={(number) => {
                setPin(number);
              }}
            />
          )
        }
        footer={type?.id != "cabin" ? "Enter or generate pin" : null}
        setValue={(text) => setPin(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {type?.id != "admin" && type?.id != "cabin" && (
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Credit *"}
          placeholder={"Credit"}
          value={credit}
          setValue={(text) => setCredit(text)}
        />
      )}

      {type?.id != "cabin" && (
        <NtModalDatePickerField
          containerStyle={{ marginTop: 15 }}
          title={"Expiration Date *"}
          placeholder={"Select Date"}
          value={date}
          setValue={(date) => setDate(date)}
        />
      )}

      {renderTariff({ marginTop: 15, marginLeft: 4 })}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinManagementNewForm;
