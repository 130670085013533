import React, { useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import UserContext from "../../context/UserContext";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtModalAccountPicker from "../../extensions/components/NtModalAccountPicker";
import Logger from "../../common/utils/Logger";

function NtAccountPicker({ containerStyle }) {
  const context = useContext(UserContext);

  const accountPickerRef = useRef(null);

  const isAccountPresent = () => {
    return context.user?.omni;
  };

  const renderSelectAccountButton = (title) => {
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          accountPickerRef?.current?.show();
        }}
      >
        <NtText
          style={{ color: colors.blue, fontWeight: "900", fontStyle: "italic" }}
        >
          {title}
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderAccountPickerModal = () => {
    return (
      <NtModal ref={accountPickerRef}>
        <NtModalAccountPicker
          removeOptions={["ringgroup", "voicemail", "other"]}
          defaultOpenTab={"extension"}
          defaultSelectedItem={null}
          onCancel={() => {
            accountPickerRef.current?.dismiss();
          }}
          onPress={(selection) => {
            accountPickerRef.current?.dismiss();

            const omni = {
              username: selection?.item?.extension,
              password: selection?.item?.password,
              name:
                selection?.item?.effective_caller_id_name ||
                selection?.item?.effective_caller_id_number,
              domain: selection?.item?.accountcode,
            };

            //will update user now.....
            context.updateUser({ ...context.user, omni: omni });
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {isAccountPresent() && (
        <NtText style={styles.text}>
          You are signed in to send and recieve message as (
          {context.user?.omni?.username}) {renderSelectAccountButton("change")}
        </NtText>
      )}
      {!isAccountPresent() && (
        <NtText style={styles.text}>
          No account selected to send and receive messages. Please{" "}
          {renderSelectAccountButton("select account now")}
        </NtText>
      )}
      {renderAccountPickerModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtAccountPicker;
