const hardwareaccountsdata = [
  {
    id: "1",
    title: "Account 1",
  },
  {
    id: "2",
    title: "Account 2",
  },
];

export default hardwareaccountsdata;
