import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtDevider from "../../components/NtDevider";
import { parseExtension } from "../../extensions/utils/ExtensionUtils";

function NtMessageUserItem({ selected, item, onPress }) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? { backgroundColor: colors.blue, borderRadius: 6 } : {},
      ]}
      onPress={onPress}
    >
      <View style={{ paddingTop: 12, paddingBottom: 12 }}>
        <NtText style={[styles.name, selected ? { color: "white" } : {}]}>
          {item.effective_caller_id_name || parseExtension(item.extension)}
        </NtText>

        <NtText
          style={[
            styles.number,
            { marginTop: 6 },
            selected ? { color: "white" } : {},
          ]}
        >
          {parseExtension(item.extension)}
        </NtText>
      </View>
      {!selected && <NtDevider />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  name: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darkest,
  },
  number: {
    fontSize: 12,
    fontWeight: "500",
    color: colors.darker,
  },
});

export default NtMessageUserItem;
