import Logger from "../common/utils/Logger";
import client, { parseQueryParams } from "./client";
import moment from "moment-timezone";

export const fetchPins = async (domain, page, pageSize, search, filter) => {
  let endpoint = `/v3/callingcard/card?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.pin) {
    endpoint += `&pin=${filter?.pin}`;
  }

  if (filter?.name) {
    endpoint += `&name=${filter?.name}`;
  }

  if (filter?.type) {
    endpoint += `&type=${filter?.type}`;
  }

  if (filter?.orderBy) {
    endpoint += `&orderby=creationdate&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=creationdate&order=DESC`;
  }

  if (filter?.status == "active") {
    endpoint += "&active=1";
  } else if (filter?.status == "inactive") {
    endpoint += "&active=0";
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchCdrs = async (page, pageSize, search, filter) => {
  let endpoint = `/v3/callingcard/cdrs?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.pin) {
    endpoint += `&pin=${filter?.pin}`;
  }

  if (filter?.callid) {
    endpoint += `&callid=${filter?.callid}`;
  }

  if (filter?.terminatecauseid) {
    endpoint += `&terminatecauseid=${filter?.terminatecauseid}`;
  }

  if (filter?.type) {
    endpoint += `&type=${filter?.type}`;
  }

  if (filter?.dateFrom) {
    endpoint += `&dateFilterFrom=${moment(filter?.dateFrom)?.toISOString()}`;
  }

  if (filter?.dateTo) {
    endpoint += `&dateFilterTo=${moment(filter?.dateTo)?.toISOString()}`;
  }

  if (filter?.to) {
    endpoint += `&destination=${filter?.to}`;
  }

  if (filter?.from) {
    endpoint += `&src=${filter?.from}`;
  }

  if (filter?.calldirection) {
    endpoint += `&direction=${filter?.calldirection}`;
  }

  if (!filter?.archived) {
    endpoint += "&archived=0";
  }

  // if something was sent to the filter for sorting, will respect it but if not, will sort by creation date descending
  if (filter?.orderBy) {
    endpoint += `&orderby=starttime&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=starttime&order=DESC`;
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const createPin = async (params) => {
  const body = {
    pin: params?.pin,
    name: params?.name,
    expirationdate: moment(params?.date).format("YYYY-MM-DD"),
    type: params?.type,
    tariff: params?.tariff,
    status: 1,
  };

  if (params?.credit) {
    body.initialbalance = params?.credit;
  }

  if (params?.type == "cabin") {
    body.expirationdate = null;
  }

  const response = await client.post(`/v3/callingcard/card`, body);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const deletePin = async (id) => {
  const response = await client.delete(`/v3/callingcard/card/${id}`);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updatePin = async (id, params) => {
  const date = moment(params?.date);

  const body = {
    pin: params?.pin,
    name: params?.name,
    expirationdate: date?.isValid() ? date.format("YYYY-MM-DD") : null,
    initialbalance: params?.credit,
    status: 1,
  };

  const response = await client.patch(`/v3/callingcard/card/${id}`, body);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const resetPin = async (date = null, list = null) => {
  let body = {};
  if (list) {
    Logger("Will try to reset by list");
    body.list = list;
  } else {
    body.list = "all";
  }

  if (date) {
    body.archive_cdrs_prior_to = moment(date)?.toISOString();
  }

  let endpoint = `/v3/callingcard/card/type/cabin/reset`;

  const response = await client.post(endpoint, body);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchReportSchedules = async (page, pageSize, search, filter) => {
  let endpoint = `/v2/reportschedule?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.status == "active") {
    endpoint += "&status=1";
  } else if (filter?.status == "inactive") {
    endpoint += "&status=0";
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updateReportSchedule = async (id, params) => {
  const body = {
    receiver: params?.receiver,
    subject: params?.subject,
    message: params?.message,
    status: params?.status,
    use_local_timezone: params?.timezone,
  };

  const response = await client.patch(`/v2/reportschedule/id/${id}`, body);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchTariffs = async (type, page = 1, pageSize = 25) => {
  let endpoint = `/v3/callingcard/tariff?page=${page}&pagesize=${pageSize}&orderby=id&status=1`;

  if (type) {
    endpoint += `&type=${type}`;
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchCallingRates = async (
  tariff,
  page = 1,
  pageSize = 25,
  search,
  filter
) => {
  let endpoint = `/v3/callingcard/tariff/${tariff}/rate?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const createCallingRate = async (tariff, params) => {
  const body = {
    destination: params?.destination,
    cost: parseFloat(params?.cost),
    price: parseFloat(params?.price),
    name: params?.name,
  };

  const response = await client.post(
    `/v3/callingcard/tariff/${tariff}/rate`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updateCallingRate = async (id, tariff, params) => {
  const body = {
    cost: parseFloat(params?.cost),
    price: parseFloat(params?.price),
    name: params?.name,
    status: params?.status,
  };

  const response = await client.patch(
    `/v3/callingcard/tariff/${tariff}/rate/${id}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchAutoGeneratedPin = (type) => {
  return client.get(`/v3/utils/uniquerandom/callingcard/pin/${type}`);
};

export const fetchLocalTimezone = () => {
  return client.get("/v3/utils/localtimezone");
};
