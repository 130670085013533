import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import { parseExtension } from "../utils/ExtensionUtils";
import NtTabController from "../../components/NtTabController";
import { fetchExtension, updateExtension } from "../../api/extension";
import settings from "../../config/settings";
import NtModalError from "../../components/NtModalError";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { parseBoolean } from "../../common/utils/ControllerUtils";

function NtExtensionUpdateForm({ item, onCancel, onUpdate }) {
  const tollAllowedOptions = [
    {
      id: "t",
      title: "Yes",
    },
    {
      id: "f",
      title: "No",
    },
  ];

  const tabs = [
    {
      id: "physical",
      title: "Physical",
      prefix: settings.accountPrefixPhy,
    },
    {
      id: "app",
      title: "Mobile App",
      prefix: settings.accountPrefixApp,
    },
  ];

  const [tab, setTab] = useState(tabs[0]);
  const [extension, setExtension] = useState(item);

  const [callerIdName, setCallerIdName] = useState(
    item.effective_caller_id_name || ""
  );
  const [outboundCallerIdName, setOutboundCallerIdName] = useState(
    item.outbound_caller_id_name || ""
  );
  const [outboundCallerIdNumber, setOutboundCallerIdNumber] = useState(
    item.outbound_caller_id_number || ""
  );
  const [password, setPassword] = useState(item.password || "");
  const [tollAllow, setTollAllow] = useState(
    tollAllowedOptions.find((each) => each.id == (item.toll_allow ? "t" : "f"))
  );
  const [description, setDescription] = useState(item.description || "");

  //call forward settings
  const [forwardAllEnabled, setForwardAllEnabled] = useState(
    parseBoolean(item.forward_all_enabled)
  );
  const [forwardAllDestination, setForwardAllDestination] = useState(
    item.forward_all_destination || ""
  );
  const [forwardBusyEnabled, setForwardBusyEnabled] = useState(
    parseBoolean(item.forward_busy_enabled)
  );
  const [forwardBusyDestination, setForwardBusyDestination] = useState(
    item.forward_busy_destination || ""
  );
  const [forwardNoAnswerEnabled, setForwardNoAnswerEnabled] = useState(
    parseBoolean(item.forward_no_answer_enabled)
  );
  const [forwardNoAnswerDestination, setForwardNoAnswerDestination] = useState(
    item.forward_no_answer_destination || ""
  );
  const [forwardNoRegisteredEnabled, setForwardNoRegisteredEnabled] = useState(
    parseBoolean(item.forward_user_not_registered_enabled)
  );
  const [forwardNoRegisteredDestination, setForwardNoRegisteredDestination] =
    useState(item.forward_user_not_registered_destination || "");

  //for the tipe....
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    if (tab?.id == "physical") {
      return callerIdName && password && description;
    } else {
      return callerIdName && password;
    }
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      effective_caller_id_name: callerIdName,
      outbound_caller_id_name: outboundCallerIdName,
      outbound_caller_id_number: outboundCallerIdNumber,
      toll_allow: tollAllow?.id,
      description: description,

      forward_all_enabled: forwardAllEnabled,
      forward_all_destination: forwardAllDestination,
      forward_busy_enabled: forwardBusyEnabled,
      forward_busy_destination: forwardBusyDestination,
      forward_no_answer_enabled: forwardNoAnswerEnabled,
      forward_no_answer_destination: forwardNoAnswerDestination,
      forward_user_not_registered_enabled: forwardNoRegisteredEnabled,
      forward_user_not_registered_destination: forwardNoRegisteredDestination,
    };

    const response = await updateExtension(
      context.user?.environment?.domain,
      extension?.extension,
      params
    );

    Logger("Extension Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleExtensionInfoFetch = async (tab = type) => {
    setLoadingInfo(true);

    const response = await fetchExtension(
      context.user?.environment?.domain,
      `${tab?.prefix}${parseExtension(extension?.extension)}`
    );

    if (response.ok) {
      Logger("Got Extension Info ", response);
      setExtension(response.data);
      setCallerIdName(response?.data?.effective_caller_id_name || "");
      setOutboundCallerIdName(response?.data?.outbound_caller_id_name || "");
      setOutboundCallerIdNumber(
        response?.data?.outbound_caller_id_number || ""
      );
      setPassword(response?.data?.password || "");
      setTollAllow(
        tollAllowedOptions.find(
          (each) => each.id == (response?.data?.toll_allow ? "t" : "f")
        )
      );
      setDescription(response?.data?.description || "");

      //call forward settigns
      setForwardAllEnabled(parseBoolean(response?.data?.forward_all_enabled));
      setForwardAllDestination(response?.data?.forward_all_destination || "");

      setForwardBusyEnabled(parseBoolean(response?.data?.forward_busy_enabled));
      setForwardBusyDestination(response?.data?.forward_busy_destination || "");

      setForwardNoAnswerEnabled(
        parseBoolean(response?.data?.forward_no_answer_enabled)
      );
      setForwardNoAnswerDestination(
        response?.data?.forward_no_answer_destination || ""
      );

      setForwardNoRegisteredEnabled(
        parseBoolean(response?.data?.forward_user_not_registered_enabled)
      );
      setForwardNoRegisteredDestination(
        response?.data?.forward_user_not_registered_destination || ""
      );
    }

    setLoadingInfo(false);
  };

  const renderTabs = (containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={tabs}
          selection={tab}
          onPress={(item) => {
            if (item?.id != tab?.id) {
              handleExtensionInfoFetch(item);
            }

            setTab(item);
          }}
        />

        {loadingInfo && (
          <NtActivityIndicator
            color={colors.almostBlack}
            size="small"
            containerStyle={{ marginLeft: 10 }}
          />
        )}
      </View>
    );
  };

  const renderTollAllowed = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText
          style={[styles.fieldTitle, { marginLeft: 4, fontWeight: "400" }]}
        >
          Toll Allowed
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={tollAllowedOptions}
          selection={tollAllow}
          onPress={(item) => {
            setTollAllow(item);
          }}
        />
      </View>
    );
  };

  const renderCallForwardEnabledButton = (value, setValue) => {
    return (
      <NtTouchableEffect
        style={{
          backgroundColor: colors.lighestGray,
          padding: 6,
          borderRadius: 20,
          marginRight: -6,
        }}
        onPress={() => {
          setValue(!value);
        }}
      >
        <NtMaterialIcon
          name={"check-bold"}
          color={value ? "green" : colors.lighGray}
        />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Extension</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update extension{" "}
        <NtText style={{ fontWeight: "900" }}>
          {parseExtension(extension?.extension)}
        </NtText>
      </NtText>

      {renderTabs({ marginTop: 8, marginBottom: 25 })}

      <ScrollView
        scrollEnabled={true}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Caller Name *"}
          placeholder={"Caller Id Name"}
          value={callerIdName}
          setValue={(text) => setCallerIdName(text)}
        />
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          titleStyle={{ fontWeight: "400" }}
          title={"Outbound Caller Name"}
          placeholder={"Outbound Caller Name"}
          value={outboundCallerIdName}
          setValue={(text) => setOutboundCallerIdName(text)}
        />
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Outbound Caller Number"}
          titleStyle={{ fontWeight: "400" }}
          placeholder={"Outbound Caller Number"}
          value={outboundCallerIdNumber}
          setValue={(text) => setOutboundCallerIdNumber(text)}
        />

        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Password *"}
          placeholder={"Password"}
          value={password}
          setValue={(text) => setPassword(text)}
        />

        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          titleStyle={{ fontWeight: tab?.id == "physical" ? "700" : "400" }}
          title={`Description ${tab?.id == "physical" ? "*" : ""}`}
          placeholder={"Description"}
          value={description}
          setValue={(text) => setDescription(text)}
        />

        <View style={{ marginTop: 15 }}>
          <NtText style={[styles.fieldTitle, { marginLeft: 10 }]}>
            Call Forward
          </NtText>
          <View
            style={{
              marginTop: 4,
              paddingLeft: 15,
              paddingRight: 15,
              backgroundColor: colors.lighestGray,
              borderRadius: 25,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 25,
              }}
            >
              <NtModalInputField
                containerStyle={{ flex: 1 }}
                title={"Forward All"}
                placeholder={"Destination"}
                value={forwardAllDestination}
                setValue={(text) => {
                  setForwardAllEnabled(text?.length > 0);
                  setForwardAllDestination(text);
                }}
                inputStyle={{ backgroundColor: "white" }}
                renderAction={renderCallForwardEnabledButton(
                  forwardAllEnabled,
                  setForwardAllEnabled
                )}
              />

              <NtModalInputField
                containerStyle={{ flex: 1, marginLeft: 10 }}
                title={"Forward Busy"}
                placeholder={"Destination"}
                value={forwardBusyDestination}
                setValue={(text) => {
                  setForwardBusyEnabled(text?.length > 0);
                  setForwardBusyDestination(text);
                }}
                inputStyle={{ backgroundColor: "white" }}
                renderAction={renderCallForwardEnabledButton(
                  forwardBusyEnabled,
                  setForwardBusyEnabled
                )}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 25,
                marginBottom: 30,
              }}
            >
              <NtModalInputField
                containerStyle={{ flex: 1 }}
                title={"Forward No Answer"}
                placeholder={"Destination"}
                value={forwardNoAnswerDestination}
                setValue={(text) => {
                  setForwardNoAnswerEnabled(text?.length > 0);
                  setForwardNoAnswerDestination(text);
                }}
                inputStyle={{ backgroundColor: "white" }}
                renderAction={renderCallForwardEnabledButton(
                  forwardNoAnswerEnabled,
                  setForwardNoAnswerEnabled
                )}
              />

              <NtModalInputField
                containerStyle={{ flex: 1, marginLeft: 10 }}
                title={"Forward No Registered"}
                placeholder={"Destination"}
                value={forwardNoRegisteredDestination}
                setValue={(text) => {
                  setForwardNoRegisteredEnabled(text?.length > 0);
                  setForwardNoRegisteredDestination(text);
                }}
                inputStyle={{ backgroundColor: "white" }}
                renderAction={renderCallForwardEnabledButton(
                  forwardNoRegisteredEnabled,
                  setForwardNoRegisteredEnabled
                )}
              />
            </View>
          </View>
        </View>

        {renderTollAllowed({ marginTop: 15 })}

        <NtModalError error={error} containerStyle={{ marginTop: 6 }} />
      </ScrollView>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={`Update ${tab?.title}`}
          onPress={() => {
            if (isRequiredFields()) {
              handleUpdate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtExtensionUpdateForm;
