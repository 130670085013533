import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtDevider from "../../components/NtDevider";
import Logger from "../../common/utils/Logger";
import {
  displayNameForRecord,
  parseChannelFromAddress,
} from "../utils/MessagingUtils";
import moment from "moment-timezone";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import UserContext from "../../context/UserContext";
import { messagechanneltypes } from "../data/messagechanneltypes";
import NtChannelIcon from "./NtChannelIcon";
import { parseDisplayDate } from "../../common/utils/ControllerUtils";

function NtMessageItem({ selected, item, directory, onPress }) {
  const context = useContext(UserContext);

  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? { backgroundColor: colors.blue, borderRadius: 6 } : {},
      ]}
      onPress={onPress}
    >
      <View style={{ paddingTop: 12, paddingBottom: 12 }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtChannelIcon
            channel={parseChannelFromAddress(item.mAddress)}
            color={selected ? "white" : null}
          />
          <NtText
            style={[
              styles.name,
              { marginLeft: 8 },
              selected ? { color: "white" } : {},
            ]}
          >
            {
              displayNameForRecord(
                item.mAddress,
                directory,
                context.user?.username?.toString()
              ).name
            }
          </NtText>
        </View>
        <NtText
          style={[
            styles.message,
            { marginTop: 6, fontWeight: "600" },
            selected ? { color: "white" } : {},
          ]}
          numberOfLines={3}
        >
          {item.mMessageText}
        </NtText>
        <View
          style={{ marginTop: 6, flexDirection: "row", alignItems: "center" }}
        >
          <NtMaterialIcon
            name="clock-time-five-outline"
            color={selected ? "white" : colors.lighGray}
          />
          <NtText
            style={[
              styles.message,
              { marginLeft: 4 },
              selected ? { color: "white" } : {},
            ]}
          >
            {parseDisplayDate(item.mDate)}
          </NtText>
        </View>
      </View>
      {!selected && <NtDevider />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  name: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.almostBlack,
  },
  message: {
    fontSize: 13,
    fontWeight: "500",
    color: colors.lighGray,
  },
});

export default NtMessageItem;
