const reportschedulecolumndata = [
  {
    id: "id",
    title: "ID",
  },
  {
    id: "name",
    title: "Name",
    default: true,
  },
  {
    id: "subject",
    title: "Subject",
  },
  {
    id: "message",
    title: "Message",
  },
  {
    id: "schedule",
    title: "Frequency",
    default: true,
  },
  {
    id: "receiver",
    title: "Receiver(s)",
    default: true,
  },
  {
    id: "creationdate",
    title: "Date",
    default: true,
  },
  {
    id: "status",
    title: "Status",
  },
  {
    id: "lastrun",
    title: "Last Run",
    default: true,
  },
  {
    id: "use_local_timezone",
    title: "Run Timezone",
    default: true,
  },
  {
    id: "notes",
    title: "Notes",
  },
];
export default reportschedulecolumndata;
