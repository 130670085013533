import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtListRow from "../../components/NtListRow";
import NtModal from "../../components/NtModal";
import NtRingGroupUpdateForm from "./NtRingGroupUpdateForm";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtAccountDisplay from "./NtAccountDisplay";

function NtRingGroupItem({ item, columns, onUpdate, onDelete }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtRingGroupUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
          onDelete={() => {
            updateModalRef.current?.dismiss();
            if (onDelete) {
              onDelete();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "ring_group_call_timeout") {
            return `${item[column]}s`;
          } else if (
            column == "ring_group_enabled" ||
            column == "ring_group_forward_enabled"
          ) {
            return item[column] ? "Enabled" : "Disabled";
          } else if (column == "destination_numbers") {
            return (
              <View>
                {item[column]?.map((each) => (
                  <View
                    key={each}
                    style={{
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 3,
                      paddingBottom: 3,
                      borderRadius: 15,
                      backgroundColor: colors.lighestGray,
                      marginBottom: 6,
                    }}
                  >
                    <NtText>{each}</NtText>
                  </View>
                ))}
              </View>
            );
          } else if (column == "ring_group_timeout_action") {
            return <NtAccountDisplay item={item[column]} />;
          }
        }}
        onActionPress={() => {
          updateModalRef.current?.show();
        }}
      />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtRingGroupItem;
