import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtModalError from "../../components/NtModalError";
import { updateVoicemailBox } from "../../api/voicemail";
import { deleteHardwareFile, fetchProvisioningFile } from "../../api/hardware";
import hardwareaccountsdata from "../data/hardwareaccountsdata";
import NtTabController from "../../components/NtTabController";
import NtModalAccountPickerField from "./NtModalAccountPickerField";

function NtHardwareUpdateForm({ item, onCancel, onUpdate, onDelete }) {
  const [extension, setExtension] = useState(null);
  const [accountsData, setAccountsData] = useState([]);
  const [password, setPassword] = useState(item.voicemail_password);
  const [description, setDescription] = useState(item.voicemail_description);
  const [account, setAccount] = useState(hardwareaccountsdata[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return password;
  };

  function xmlToJson(xml) {
    let obj = {};
    if (xml.nodeType === 1) {
      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          let attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      obj = xml.nodeValue;
    }
    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        let item = xml.childNodes.item(i);
        let nodeName = item.nodeName;
        if (typeof obj[nodeName] === "undefined") {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push === "undefined") {
            let old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
    return obj;
  }

  const parseXml = (xmlString) => {
    let parser = new DOMParser();
    let xml = parser.parseFromString(xmlString, "text/xml");
    const json = xmlToJson(xml);

    let accounts = [];

    hardwareaccountsdata?.map((eachAccount) => {
      let details = {};
      json?.gs_provision?.config?.item?.map((each) => {
        if (
          each["@attributes"]?.name ==
          `account.${eachAccount.id}.sip.outboundProxy.1`
        ) {
          details.proxy = each?.part["#text"]?.split(":")[0];
          details.port = each?.part["#text"]?.split(":")[1];
        } else if (
          each["@attributes"]?.name == `account.${eachAccount.id}.sip.server.1`
        ) {
          details.domain = each?.part["#text"];
        } else if (
          each["@attributes"]?.name ==
          `account.${eachAccount.id}.sip.subscriber`
        ) {
          each?.part?.map((eachPart) => {
            if (eachPart["@attributes"]?.name == "userid") {
              details.extension = eachPart["#text"];
            } else if (eachPart["@attributes"]?.name == "password") {
              details.password = eachPart["#text"];
            } else if (eachPart["@attributes"]?.name == "name") {
              details.name = eachPart["#text"];
            }
          });
        }
      });

      if (Object.keys(details)?.length > 0) {
        accounts.push({ account: eachAccount.id, details: details });
      }
    });

    return accounts;
  };

  const fetchData = (name) => {
    const data = accountsData.find((each) => each.account == account?.id);

    if (data && Object.keys(data)?.length > 0) {
      if (name == "extension") {
        return {
          name: "extension",
          value: data?.details?.extension,
          item: {
            extension: data?.details?.extension,
            password: data?.details?.password,
            effective_caller_id_name: data?.details?.name,
          },
        };
      }
      return data?.details[name];
    } else {
      return "";
    }
  };

  const handleFileFetch = async () => {
    setLoading(true);
    setError(null);

    const response = await fetchProvisioningFile(item.status, item.mac);
    Logger("Fetching the file data", response);
    if (response.ok) {
      const data = parseXml(response.data);
      setAccountsData(data);
      Logger("Just finished the data", data);
    }

    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      password: password,
      description: description,
      status: true,
    };

    const response = await updateVoicemailBox(
      context.user?.environment?.domain,
      params,
      item.voicemail_id
    );

    Logger("Voicemail Box Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    const response = await deleteHardwareFile(item?.mac, item?.status);
    Logger("Hardware Delete Response", response);

    if (response.ok) {
      if (onDelete) {
        onDelete();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  useEffect(() => {
    handleFileFetch();
  }, []);

  const renderAccounts = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Account *
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={hardwareaccountsdata}
          selection={account}
          onPress={(item) => {
            setAccount(item);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Hardware</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update Hardward Configuration
      </NtText>

      <NtModalAccountPickerField
        containerStyle={{ marginTop: 25 }}
        title={"Extension *"}
        placeholder={"Extension *"}
        value={fetchData("extension")}
        removeOptions={["ringgroup", "voicemail", "other"]}
        setValue={(text) => {
          Logger("----------- selected Extension", text);
          setExtension(text);
        }}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Proxy *"}
        placeholder={"Proxy"}
        value={fetchData("proxy")}
        setValue={(text) => setPassword(text)}
      />
      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Port *"}
        placeholder={"Port"}
        value={fetchData("port")}
        setValue={(text) => setDescription(text)}
      />

      {renderAccounts({ marginTop: 15 })}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {item.status == "pending" && (
            <NtModalActiveButton
              containerStyle={{ backgroundColor: "red" }}
              title={"Delete Configuration File"}
              icon={"delete"}
              onPress={() => {
                handleDelete();
              }}
              enabled={true}
            />
          )}
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={
              item?.voicemail_enabled == "false"
                ? "Update & Activate"
                : "Update"
            }
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtHardwareUpdateForm;
