import Logger from "../common/utils/Logger";
import client from "./client";

export const fetchVoicemailBox = (
  domain,
  extension,
  page,
  pageSize,
  search,
  filter
) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${extension}/voicemail/box?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  //sort
  if (filter?.orderBy) {
    endpoint += `&orderby=insert_date&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=insert_date&order=DESC`;
  }

  return client.get(endpoint);
};

export const createVoicemailBox = (domain, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${params?.number}/voicemail/box`;

  const body = {
    voicemail_enabled: true,
  };

  if (params?.description) {
    body.voicemail_description = params?.description;
  }

  return client.post(endpoint, body);
};

export const fetchVoicemails = (
  domain,
  extension,
  page,
  pageSize,
  search,
  filter
) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${extension}/voicemail/message?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  //sort
  if (filter?.orderBy) {
    endpoint += `&orderby=insert_date&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=insert_date&order=DESC`;
  }

  return client.get(endpoint);
};

export const updateVoicemailBox = (domain, params, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${id}/voicemail/box/${id}`;

  const body = {
    voicemail_password: params?.password,
  };

  if (params?.description) {
    body.voicemail_description = params?.description;
  }

  if (params?.hasOwnProperty("status")) {
    body.voicemail_enabled = params?.status;
  }

  return client.patch(endpoint, body);
};

export const deleteVoicemailBox = (domain, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${id}/voicemail/box/${id}`;

  return client.delete(endpoint);
};
