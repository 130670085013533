import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import Logger from "../../common/utils/Logger";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import colors from "../../config/colors";
import { fetchReportSchedules } from "../../api/billing";
import settings from "../../config/settings";
import BasePinController from "./BasePinController";
import reportschedulecolumndata from "../data/reportschedulecolumndata";
import NtReportScheduleItem from "../components/NtReportScheduleItem";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListHeader from "../../components/NtListHeader";
import cronstrue from "cronstrue";
import {
  asyncStorageSetData,
  parseDisplayDate,
  parseSimpleDisplayDate,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtListEmpty from "../../components/NtListEmpty";
import NtTabController from "../../components/NtTabController";

class ReportScheduleController extends BasePinController {
  state = {
    ...super.state,
    loading: false,
    totalItems: 0,
    data: [],
    searchText: "",
    searchFilter: null,
    selectedTab: null,
  };

  tabs = [
    {
      id: "active",
      title: "Active",
    },
    {
      id: "inactive",
      title: "Inactive",
    },
  ];

  column_location = "@reportschedule_column_storage";
  page = 1;

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.state.selectedTab = this.tabs[0];
    this.setState({ selectedTab: this.state.selectedTab });
    this.handleDefaultColumnsFetch(
      this.column_location,
      reportschedulecolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.moduleCallingCards);
  };

  handleDataFetch = async () => {
    Logger("Fetching Reports Schedule data");

    this.setState({ loading: true });

    const response = await fetchReportSchedules(
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      { status: this.state.selectedTab?.id }
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger(
        "Loading data total items",
        response.data?.reportSchedules?.length
      );

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.reportSchedules,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.reportSchedules),
        });
      }
    }

    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Reports Schedule export data");

    const response = await fetchReportSchedules(
      1,
      10000000,
      this.state.searchText,
      { status: this.state.selectedTab?.id }
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      return response.data.reportSchedules;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "creationdate") {
      return parseSimpleDisplayDate(item[column]);
    } else if (column == "receiver") {
      return `"${item[column]}"`;
    } else if (column == "schedule") {
      return cronstrue.toString(item[column]);
    } else if (column == "lastrun") {
      return item[column] ? `"${parseDisplayDate(item[column])}"` : "N/A";
    } else if (column == "use_local_timezone") {
      if (item[column] == 0) {
        return "UTC";
      }
      return "Local Time";
    }

    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderTabs = () => {
    return (
      <NtTabController
        containerStyle={{ marginTop: 20, marginBottom: 10 }}
        options={this.tabs}
        selection={this.state.selectedTab}
        onPress={(item) => {
          if (item?.id == this.state.selectedTab?.id) {
            //no need to reload since we are not changing tabs
            return;
          }

          this.state.selectedTab = item;
          this.setState({
            selectedTab: this.state.selectedTab,
            searchText: "",
            data: [],
          });
          this.page = 1;
          this.handleDataFetch();
        }}
      />
    );
  };

  renderRow = (item, index) => {
    return (
      <NtReportScheduleItem
        key={item?.id}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast("Success", "Successfully updated a report shcedule");
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Report Schedule"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        {this.renderTabs()}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={reportschedulecolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"report-shcedule"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />

          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default ReportScheduleController;
