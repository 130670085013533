import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { deletePin, resetPin, updatePin } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import moment from "moment-timezone";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import NtPinManagementResetconfirmForm from "./NtPinManagementResetConfirmForm";
import NtModal from "../../components/NtModal";
import { parseError } from "../../api/client";
import { parseDecimalPlaces } from "../../common/utils/ControllerUtils";
import NtModalError from "../../components/NtModalError";
import NtPinManagementAutoGenerateButton from "./NtPinManagementAutoGenerateButton";
import NtArchiveCabinDataAllForm from "./NtArchiveCabinDataAllForm";

function NtPinManagementUpdateForm({ item, onCancel, onUpdate, onReset }) {
  const [pin, setPin] = useState(item.pin);
  const [name, setName] = useState(item.name);
  const [date, setDate] = useState(moment(item.expirationdate));
  const [credit, setCredit] = useState(parseDecimalPlaces(item.credit));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);
  const isActive = item.status == 1;
  const type = item?.type;
  const archiveModalRef = useRef();

  const isRequiredFields = () => {
    if (type == "admin") {
      return name, date;
    } else if (type == "cabin") {
      return name, credit;
    }
    return name, credit && date;
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      pin: pin,
      name: name,
      date: date,
      credit: credit,
    };

    const response = await updatePin(item.id, params);

    Logger("Pin response Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    setError(null);
    const response = await deletePin(item.id);
    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError("Unable to process request");
    }

    setLoading(false);
  };

  const handleReset = async () => {
    setLoading(true);

    setError(null);
    const response = await resetPin(context.user?.environment?.domain, [
      item.id,
    ]);
    Logger("Finished reseting", response);
    if (response.ok) {
      if (onReset) {
        onReset();
      }
    } else {
      setError("Unable to process request");
    }

    setLoading(false);
  };

  const renderArchiveDataForm = () => {
    return (
      <NtModal ref={archiveModalRef}>
        <NtArchiveCabinDataAllForm
          singleCabin={item?.pin}
          onCancel={() => {
            archiveModalRef.current?.dismiss();
          }}
          onDone={() => {
            archiveModalRef.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update pin</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This update will re-enable the pin.
      </NtText>

      {type != "cabin" && (
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Pin *"}
          placeholder={"Pin"}
          value={pin}
          setValue={(text) => setPin(text)}
          renderAction={
            <NtPinManagementAutoGenerateButton
              containerStyle={{ marginRight: -6 }}
              type={item?.type}
              onPinAvailable={(number) => {
                setPin(number);
              }}
            />
          }
          footer={"Enter or generate pin"}
        />
      )}

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {type != "admin" && (
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Credit *"}
          placeholder={"Credit"}
          footer={`Initial credit was $${parseDecimalPlaces(
            item.initialbalance
          )}`}
          value={credit}
          setValue={(text) => setCredit(text)}
        />
      )}

      {type != "cabin" && (
        <NtModalDatePickerField
          containerStyle={{ flex: 1, marginTop: 15 }}
          title={"Select Date *"}
          placeholder={"Select Date"}
          value={date}
          setValue={(text) => setDate(text)}
        />
      )}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent:
            isActive || type == "cabin" ? "space-between" : "flex-end",
          marginTop: 25,
        }}
      >
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isActive && (
            <NtModalActiveButton
              containerStyle={{ marginRight: 15 }}
              title={"Deactivate"}
              onPress={() => {
                handleDelete();
              }}
              enabled={true}
              icon={"delete"}
            />
          )}

          {type == "cabin" && (
            <NtModalActiveButton
              containerStyle={{}}
              title={"Reset Billing Period"}
              onPress={() => {
                archiveModalRef?.current?.show();
              }}
              enabled={true}
              icon={"archive"}
            />
          )}
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={isActive ? "Update" : "Update & Activate"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
      {renderArchiveDataForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinManagementUpdateForm;
