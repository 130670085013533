const ringgroupcsvcolumndata = [
  {
    id: "ring_group_uuid",
    title: "ID",
  },
  {
    id: "ring_group_extension",
    title: "Extension",
    default: true,
  },
  {
    id: "ring_group_caller_id_name",
    title: "Caller Name",
    default: true,
  },
  {
    id: "ring_group_caller_id_number",
    title: "Caller Number",
    default: true,
  },
  {
    id: "ring_group_call_timeout",
    title: "Call Timeout",
    default: true,
  },
  {
    id: "ring_group_strategy",
    title: "Strategy",
    default: true,
  },
  {
    id: "ring_group_timeout_action",
    title: "Timeout Action",
    default: true,
  },
  {
    id: "destination_numbers",
    title: "Destinations",
  },
  {
    id: "ring_group_forward_destination",
    title: "Forward Destination",
  },
  {
    id: "ring_group_timeout_app",
    title: "Timeout App",
  },
  {
    id: "ring_group_timeout_data",
    title: "Timeout Data",
  },
  {
    id: "ring_group_forward_enabled",
    title: "Forward Enabled",
  },
  {
    id: "ring_group_enabled",
    title: "Status",
  },
];
export default ringgroupcsvcolumndata;
