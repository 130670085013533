const terminationcausedata = [
  {
    id: "UNKNOWN",
    title: "Unknown",
  },
  {
    id: "NORMAL_CLEARING",
    title: "Completed",
  },
  {
    id: "ORIGINATOR_CANCEL",
    title: "Originator Cancel",
  },
  {
    id: "CALL_REJECTED",
    title: "Call Rejected",
  },
  {
    id: "UNSPECIFIED",
    title: "Unspecified",
  },
  {
    id: "NO_ROUTE_DESTINATION",
    title: "No Route Destination",
  },
  {
    id: "CHANNEL_UNACCEPTABLE",
    title: "Channel Unacceptable",
  },
  {
    id: "USER_BUSY",
    title: "User Busy",
  },
  {
    id: "NO_USER_RESPONSE",
    title: "No User Response",
  },
  {
    id: "NO_ANSWER",
    title: "No Answer",
  },
  {
    id: "SERVICE_UNAVAILABLE",
    title: "Service Unavailable",
  },
  {
    id: "GATEWAY_DOWN",
    title: "Gateway Down",
  },
  {
    id: "RECOVERY_ON_TIMER_EXPIRE",
    title: "Recovery on Timer Expire",
  },
  {
    id: "ONGOING_CALL",
    title: "Ongoing Call",
  },
];
export default terminationcausedata;
