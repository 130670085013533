const voicemailmessagecsvcolumndata = [
  {
    id: "voicemail_uuid",
    title: "ID",
  },
  {
    id: "caller_id_name",
    title: "Caller Name",
    default: true,
  },
  {
    id: "caller_id_number",
    title: "Caller Number",
    default: true,
  },
  {
    id: "message_length",
    title: "Duration",
    default: true,
  },
  {
    id: "created_epoch",
    title: "Date",
    default: true,
  },
];
export default voicemailmessagecsvcolumndata;
