import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import Logger from "../../common/utils/Logger";
import NtMessageUserItem from "./NtMessageUserItem";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtMessageItem from "./NtMessageItem";
import NtMessageChannelCreateModal from "./NtMessageChannelCreateModal";
import { messagechanneltypes } from "../data/messagechanneltypes";
import settings from "../../config/settings";
import NtSearchBar from "../../components/NtSearchBar";
import { parseChannelFromAddress } from "../utils/MessagingUtils";
import NtTabController from "../../components/NtTabController";
import NtPageActionButton from "../../components/NtPageActionButton";
import { fetchExtensions } from "../../api/extension";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtListEmpty from "../../components/NtListEmpty";

let page = 1;

function NtMessageUserDirectory({ onPress }, ref) {
  const tabs = [
    {
      title: "Messages",
      id: "message",
      description: "conversation",
      request: async (page, search) => {
        // response = await fetchConversations(context?.user?.username?.toString());

        // if (response.ok) {
        //   setData(response.data);
        //   setMaster(response.data);
        // }

        return { ok: true, data: { total: 0, data: [] } };
      },
    },
    {
      title: "Directory",
      id: "directory",
      description: "contact",
      request: async (page, search) => {
        const response = await fetchExtensions(
          context.user?.environment?.domain,
          page,
          settings.pageSizeLoad,
          settings.accountPrefixApp,
          search,
          {}
        );

        if (response.ok) {
          response.data.data = response.data.extensions;
        }

        return response;
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [master, setMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [tab, setTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState("");
  const context = useContext(UserContext);
  const newConversationRef = useRef();

  useImperativeHandle(ref, () => ({
    // https://stackoverflow.com/questions/43953791/calling-functions-from-other-components-in-react-native
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    // method1: () => { localMethod1() },

    reload: () => {
      if (tab?.id == tabs[0].id) {
        handleDataFetch(tab, searchText);
      }
    },
  }));

  const handleDataFetch = async (tab, search) => {
    Logger("Fetching data for tab", tab);

    setLoading(true);

    const response = await tab?.request(page, search);

    Logger("Data response for tab GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.data?.length);

      if (page == 1) {
        Logger("Loading for the first time.");
        setData(response.data?.data);
        setTotalItems(response.data?.total);
      } else {
        Logger("Loading more.");
        setData(data.concat(response.data?.data));
      }
    }
    setLoading(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    page = 1;
    handleDataFetch(tab, text);
  };

  const handleChannelFetch = (channel) => {
    if (channel) {
      return messagechanneltypes.find((each) => each.id == channel);
    }
    return messagechanneltypes[0];
  };

  const handlePress = (address, channel) => {
    if (onPress) {
      onPress({
        address: address,
        channel: handleChannelFetch(channel),
      });
    }
    setSelected(address);
  };

  const renderNewConversationModal = () => {
    return (
      <NtModal ref={newConversationRef}>
        <NtMessageChannelCreateModal
          onCreate={(params) => {
            Logger("Params passed", params);
            let address = "";
            if (
              params?.destination?.length > 1 &&
              params.channel?.id == "app"
            ) {
              Logger("Preparing a group chat....");

              //TODO: ADD MYSELF BACK AGAIN
              //will need to add myself to the destinations.....
              // const myself = directory?.find(
              //   (each) => each.number == context.user?.username?.toString()
              // );
              // params.destination = [...params?.destination, myself];

              //will now sort
              params?.destination?.sort((a, b) => a.number - b.number);

              params?.destination?.map((each) => {
                address +=
                  each.number?.toString() + "@" + global.messagingService + ":";
              });

              address = address?.substring(0, address?.length - 1);
            } else if (params.channel?.id != "app") {
              Logger("Single channel ", params.channel?.id);
              address =
                params?.destination[0]?.externalNumber?.toString() +
                "@" +
                params?.channel?.service;
            } else {
              Logger("Single chat");
              address =
                params?.destination[0]?.number?.toString() +
                "@" +
                global.messagingService;
            }
            handlePress(address, params?.channel?.id);
            newConversationRef?.current?.dismiss();
          }}
          onCancel={() => {
            newConversationRef?.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  const renderRow = (item, index) => {
    if (tab?.id == "directory") {
      return (
        <NtMessageUserItem
          onPress={() => {
            handlePress(item.extension, "app");
          }}
          key={item.extension + selected}
          selected={item.extension == selected}
          item={item}
        />
      );
    } else {
      return (
        <NtMessageItem
          onPress={() => {
            const channel = parseChannelFromAddress(item.mAddress);
            handlePress(item.mAddress, channel?.id);
          }}
          key={item.mMessageId + selected}
          selected={item.mAddress == selected}
          item={item}
          // directory={directory}
        />
      );
    }
  };

  useEffect(() => {
    handleDataFetch(tabs[0], searchText);
  }, []);

  return (
    <View style={styles.container}>
      <View>
        <View style={{ padding: 15 }}>
          {/* Tabs.... */}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <NtTabController
              containerStyle={{}}
              options={tabs}
              selection={tab}
              onPress={(item) => {
                setTab(item);
                setData([]);
                setSelected(null);
                onPress(null);
                handleDataFetch(item, searchText);
                setSearchText("");
              }}
            />

            <NtPageActionButton
              icon={"plus"}
              onPress={() => {
                newConversationRef?.current?.show();
              }}
              containerStyle={{ marginLeft: 5 }}
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <NtListRecordsTitle containerStyle={{}} count={totalItems} />

            {loading && (
              <NtActivityIndicator
                containerStyle={{ marginLeft: 8 }}
                color={colors.darkest}
                size="small"
              />
            )}
          </View>
        </View>

        <NtSearchBar
          containerStyle={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
          value={searchText}
          onChangeText={(text) => {
            handleSearch(text);
          }}
        />

        <NtDevider />
      </View>

      <ScrollView
        contentContainerStyle={{ paddingBottom: 0 }}
        showsVerticalScrollIndicator={false}
      >
        <NtListEmpty visible={data?.length == 0} />
        {data.map((each, index) => renderRow(each, index))}

        {data?.length > 0 && (
          <NtLoadMoreButton
            loadingText={"Loading"}
            title="Load More Records"
            loading={loading}
            containerStyle={{
              marginTop: 30,
              marginBottom: 15,
              marginLeft: 10,
              marginRight: 10,
            }}
            onPress={() => {
              page = page + 1;
              handleDataFetch(tab, searchText);
            }}
          />
        )}
      </ScrollView>
      {renderNewConversationModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 280,
    margin: 15,
    borderRadius: 15,
    backgroundColor: "white",
  },
  title: {
    color: colors.lighGray,
    fontSize: 16,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.lighGray,
    fontSize: 14,
    fontWeight: "600",
  },
});

export default forwardRef(NtMessageUserDirectory);
