import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseController from "../../common/base/BaseController";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListHeader from "../../components/NtListHeader";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtListEmpty from "../../components/NtListEmpty";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import Logger from "../../common/utils/Logger";
import { fetchVoicemailBox } from "../../api/voicemail";
import voicemailcsvcolumndata from "../data/voicemailcsvcolumndata";
import settings from "../../config/settings";
import NtListRow from "../../components/NtListRow";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import NtVoicemailItemItem from "../components/NtVoicemailItem";
import NtVoicemailNewForm from "../components/NtVoicemailNewForm";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtModal from "../../components/NtModal";

class VoicemailController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
  };

  column_location = "@voicemail_column_storage";

  page = 1;

  addModalRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      voicemailcsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    Logger("Fetching Voicemail data");
    this.setState({ loading: true });
    const response = await fetchVoicemailBox(
      this.context.user?.environment?.domain,
      "all",
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      this.state.searchFilter
    );
    Logger("Data response GET: ", response);
    if (response.ok) {
      Logger("Loading data total items", response.data?.voicemailboxes?.length);
      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.voicemailboxes,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");
        this.setState({
          data: this.state.data.concat(response.data?.voicemailboxes),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Voicemail export data");

    const response = await fetchVoicemailBox(
      this.context.user?.environment?.domain,
      "all",
      1,
      100000,
      this.state.searchText,
      this.state.searchFilter
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      //will filter base on the tab....
      return response.data.voicemailboxes;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "voicemail_enabled") {
      return item[column] == "true" ? "Enabled" : "Disabled";
    } else if (column == "voicemail_description") {
      return `"${item[column] ? item[column] : ""}"`;
    }
    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addModalRef}>
        <NtVoicemailNewForm
          onCancel={() => {
            this.addModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.page = 1;
            this.handleDataFetch();
            this.addModalRef.current?.dismiss();
            showToast("Success", "Successfully created the new inbound route");
          }}
        />
      </NtModal>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtVoicemailItemItem
        key={item.voicemail_uuid}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully updated Voicemail Box ${item?.voicemail_id}`
          );
        }}
        onDelete={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully deleted Voicemail Box ${item?.voicemail_id}`
          );
        }}
        onPress={() => {
          this.props.navigation.navigate(routes.voicemailmessage, {
            extension: item.voicemail_id,
          });
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Voicemails"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={voicemailcsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"voicemail"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default VoicemailController;
