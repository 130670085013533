const pinstatisticcolumndata = [
  {
    id: "type",
    title: "Type",
    default: true,
  },
  {
    id: "status",
    title: "Status",
    default: true,
  },
  {
    id: "dialing_prefix",
    title: "Dialing Prefix",
    default: true,
  },
  {
    id: "default_tariff",
    title: "Tariff",
    default: true,
  },

  {
    id: "active_cards",
    title: "Total Pins Active",
    default: true,
  },
  {
    id: "total_cards",
    title: "Total Pins",
    default: true,
  },
];
export default pinstatisticcolumndata;
