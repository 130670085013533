import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtListRow from "../../components/NtListRow";
import NtModal from "../../components/NtModal";
import NtRingGroupUpdateForm from "./NtRingGroupUpdateForm";
import NtInboundRouteUpdateForm from "./NtInboundRouteUpdateForm";
import Logger from "../../common/utils/Logger";
import NtText from "../../components/NtText";
import destinationactiondata from "../data/destinationactiondata";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtAccountDisplay from "./NtAccountDisplay";

function NtInboundRouteItem({ item, columns, onUpdate, onDelete }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtInboundRouteUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
          onDelete={() => {
            updateModalRef.current?.dismiss();
            if (onDelete) {
              onDelete();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "dialplan_destination_actions") {
            return <NtAccountDisplay item={item[column]} />;
          }
        }}
        onActionPress={() => {
          updateModalRef.current?.show();
        }}
      />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtInboundRouteItem;
