const voicemailcsvcolumndata = [
  {
    id: "voicemail_uuid",
    title: "ID",
  },
  {
    id: "voicemail_id",
    title: "Voicemail",
    default: true,
  },
  {
    id: "voicemail_password",
    title: "password",
    default: true,
  },
  {
    id: "voicemail_description",
    title: "Description",
    default: true,
  },
  {
    id: "voicemail_enabled",
    title: "Status",
    default: true,
  },
];
export default voicemailcsvcolumndata;
