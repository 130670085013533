import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";

import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import { handlePinUploadReset } from "../utils/PinUploadParser";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { resetPin } from "../../api/billing";
import NtTabController from "../../components/NtTabController";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import NtModalError from "../../components/NtModalError";

function NtArchiveCabinDataAllForm({ singleCabin = null, onCancel, onDone }) {
  const selectTypes = [
    {
      id: "all-cabins",
      title: "All Cabins",
    },
    {
      id: "specific-cabins",
      title: "Specific Cabins",
    },
  ];

  const archiveTypes = [
    {
      id: "all",
      title: "All Billing",
    },
    {
      id: "prior",
      title: "Billing Prior to Date",
    },
  ];

  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [selectType, setSelectType] = useState(selectTypes[0]);
  const [archiveType, setArchiveType] = useState(archiveTypes[0]);

  const context = useContext(UserContext);

  const handleProcess = async () => {
    setLoading(true);

    let list = null;
    let priorDate = null;
    if (singleCabin) {
      list = [singleCabin];
    } else if (selectType?.id == "specific-cabins") {
      //will need to get the list
      const response = await handlePinUploadReset(
        file,
        context.user?.environment
      );
      list = response?.data;
    }

    if (archiveType?.id == "prior") {
      //will need to get the date
      priorDate = date;
    }

    const response = await resetPin(priorDate, list);
    Logger("Finished reseting Pin response", response);

    setUploadResponse(response);
    setLoading(false);
  };

  const handleFailureDownload = async () => {
    const fileData = JSON.stringify(
      uploadResponse?.data?.rejected?.obj,
      null,
      2
    );

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "failure-uploaded-resets.txt";
    link.href = url;
    link.click();
  };

  const handleDone = () => {
    if (onDone) {
      onDone(uploadResponse);
    }
  };

  const isRequiredFields = () => {
    if (selectType?.id == "all-cabins") {
      if (archiveType?.id == "all") {
        return true;
      } else {
        return date;
      }
    } else if (selectType?.id == "specific-cabins") {
      if (archiveType?.id == "all") {
        return file;
      } else {
        return file && date;
      }
    }
  };

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
    setUploadResponse(null);
  };

  const renderChooseCSV = () => {
    if (file) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          document.getElementById("selectFile").click();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "400", color: colors.blue }}>
          Choose CSV file
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderInfoCSV = () => {
    if (!file) {
      return null;
    }
    return (
      <View>
        <NtText>{file?.name}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name="alert-outline" />
        <NtText style={[styles.title, { marginLeft: 6 }]}>
          Reset Billing Period
        </NtText>
      </View>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will archive the CDRs & reset the credit.
      </NtText>

      {/* select */}
      {!singleCabin && (
        <View>
          <NtText style={[styles.fieldTitle, { marginLeft: 4, marginTop: 20 }]}>
            Select
          </NtText>
          <NtTabController
            containerStyle={{ marginTop: 4 }}
            options={selectTypes}
            selection={selectType}
            onPress={(item) => {
              setSelectType(item);
            }}
          />
        </View>
      )}

      {/* upload */}
      {selectType?.id == "specific-cabins" && (
        <View style={{ marginTop: 15 }}>
          <NtText style={[styles.subtitle]}>
            Upload .csv with specific cabins. Column MUST be named (case
            sensitive):{" "}
            <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>
              cabin
            </NtText>
          </NtText>

          <View
            style={{
              borderRadius: 4,
              borderWidth: 1,
              borderColor: colors.lightest,
              backgroundColor: colors.lighestGray,
              justifyContent: "center",
              padding: 10,
              marginTop: 15,
            }}
          >
            {renderChooseCSV()}
            {renderInfoCSV()}
          </View>
        </View>
      )}

      {/* archive */}
      <View style={{ marginTop: 15 }}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Archive</NtText>
        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={archiveTypes}
          selection={archiveType}
          onPress={(item) => {
            setArchiveType(item);
          }}
        />

        {archiveType?.id == "prior" && (
          <NtModalDatePickerField
            containerStyle={{ flex: 1, marginTop: 15 }}
            title={"Archive CDRs Prior to Date"}
            placeholder={"Select Date"}
            isTimeEnabled={true}
            value={date}
            setValue={(text) => {
              setDate(new Date(text));
            }}
          />
        )}
      </View>

      {/* response */}
      <View style={{ marginTop: 20 }}>
        {/* In the event that response is 409, then I show the error message only because the complete upload failed*/}
        {uploadResponse &&
          (uploadResponse?.status == 409 || uploadResponse?.status == 400) && (
            <NtModalError
              error={uploadResponse?.data?.error}
              containerStyle={{}}
            />
          )}

        {/* In the event that response is 200, then I show the list response which includes total, success, and errors */}
        {uploadResponse &&
          uploadResponse?.status != 409 &&
          uploadResponse?.status != 400 && (
            <View style={{}}>
              <NtText style={[styles.uploadResponseText]}>
                Total:{" "}
                {(uploadResponse?.data?.accepted?.obj?.length || 0) +
                  (uploadResponse?.data?.rejected?.obj?.length || 0)}
              </NtText>
              <NtText style={[styles.uploadResponseText, { marginTop: 4 }]}>
                Success: {uploadResponse?.data?.accepted?.obj?.length || 0}
              </NtText>
              <NtTouchableEffect
                style={{ marginTop: 4 }}
                onPress={() => {
                  if (uploadResponse?.data?.rejected?.obj?.length > 0) {
                    handleFailureDownload();
                  }
                }}
              >
                <NtText style={[styles.uploadResponseText, { color: "red" }]}>
                  Error: {uploadResponse?.data?.rejected?.obj?.length || 0}
                </NtText>
              </NtTouchableEffect>
            </View>
          )}
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={uploadResponse ? "Done" : "Reset Billing Period"}
          onPress={
            isRequiredFields()
              ? () => {
                  if (uploadResponse) {
                    handleDone();
                  } else {
                    handleProcess();
                  }
                }
              : null
          }
          enabled={isRequiredFields()}
        />
      </View>

      <input
        id="selectFile"
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={changeHandler}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtArchiveCabinDataAllForm;
