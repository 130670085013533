import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtTextInput from "./NtTextInput";
import NtText from "./NtText";

function NtModalInputField({
  containerStyle,
  title,
  footer,
  placeholder,
  value,
  setValue,
  titleStyle,
  inputStyle,
  renderAction,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText
        style={[styles.title, { marginLeft: 10, marginBottom: 4 }, titleStyle]}
      >
        {title}
      </NtText>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtTextInput
          textStyle={{ flex: 1 }}
          containerStyle={[styles.input, inputStyle]}
          placeholder={placeholder}
          placeholderTextColor={colors.lighGray}
          value={value}
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={(text) => setValue(text)}
          renderAction={renderAction}
        />
      </View>

      {footer && (
        <NtText style={[styles.footer, { marginTop: 4, marginLeft: 10 }]}>
          {footer}
        </NtText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
  footer: {
    fontSize: 11,
    fontWeight: "600",
    color: colors.lighGray,
    fontStyle: "italic",
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: colors.lighestGray,
    borderRadius: 30,
  },
});

export default NtModalInputField;
