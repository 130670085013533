import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";

class BaseAuthController extends BaseController {
  state = {};

  parseParams = (data) => {
    const params = {
      id: data?.user_uuid,
      username: data?.username,
      email: data?.email,
    };

    return params;
  };
}

export default BaseAuthController;
