import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtModalError from "../../components/NtModalError";
import { deleteVoicemailBox, updateVoicemailBox } from "../../api/voicemail";

function NtVoicemailUpdateForm({ item, onCancel, onUpdate, onDelete }) {
  const [password, setPassword] = useState(item.voicemail_password);
  const [description, setDescription] = useState(item.voicemail_description);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return password;
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      password: password,
      description: description,
      status: true,
    };

    const response = await updateVoicemailBox(
      context.user?.environment?.domain,
      params,
      item.voicemail_id
    );

    Logger("Voicemail Box Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDesable = async () => {
    setLoading(true);

    setError(null);

    const params = {
      password: password,
      status: false,
    };

    const response = await updateVoicemailBox(
      context.user?.environment?.domain,
      params,
      item.voicemail_id
    );

    Logger("Voicemail Box Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    const response = await deleteVoicemailBox(
      context.user?.environment?.domain,
      item?.voicemail_id
    );

    if (response.ok) {
      if (onDelete) {
        onDelete();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Voicemail Box</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update Voicemail Box Settings{" "}
        <NtText style={{ fontWeight: "900" }}>{item.voicemail_id}</NtText>
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Password *"}
        placeholder={"Password"}
        value={password}
        setValue={(text) => setPassword(text)}
      />
      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Description"}
        placeholder={"Description"}
        value={description}
        setValue={(text) => setDescription(text)}
      />

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalActiveButton
            containerStyle={{ backgroundColor: "red" }}
            title={"Delete"}
            icon={"delete"}
            onPress={() => {
              handleDelete();
            }}
            enabled={true}
          />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Disable"}
            onPress={() => {
              handleDesable();
            }}
            enabled={true}
          />
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={
              item?.voicemail_enabled == "false"
                ? "Update & Activate"
                : "Update"
            }
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtVoicemailUpdateForm;
