import React, { useRef, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import NtModalAccountPicker from "./NtModalAccountPicker";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import Logger from "../../common/utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import destinationcsvcolumndata from "../data/destinationcsvcolumndata";
import destinationactiondata from "../data/destinationactiondata";

function NtModalAccountPickerField({
  containerStyle,
  title,
  placeholder,
  value,
  setValue,
  removeOptions = [],
}) {
  const accountPickerRef = useRef(null);

  const renderAccountPickerModal = () => {
    return (
      <NtModal ref={accountPickerRef}>
        <NtModalAccountPicker
          removeOptions={removeOptions}
          defaultOpenTab={value?.name}
          defaultSelectedItem={value?.value}
          onCancel={() => {
            accountPickerRef.current?.dismiss();
          }}
          onPress={(selection) => {
            accountPickerRef.current?.dismiss();
            if (setValue) {
              setValue({
                name: selection?.tab?.id,
                value: selection?.value,
                item: selection?.item,
              });
            }
          }}
        />
      </NtModal>
    );
  };

  const hasValue = () => {
    return value && Object.keys(value)?.length > 0;
  };

  const renderValue = () => {
    if (hasValue()) {
      const details = destinationactiondata?.find(
        (each) => each.id == value?.name
      );

      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <View
            style={{
              borderRadius: 20,
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 8,
              paddingRight: 8,
              backgroundColor: colors.blue,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <NtMaterialIcon name={details?.icon} color={"white"} size={16} />
            <NtText
              style={{
                fontSize: 12,
                fontWeight: "600",
                color: "white",
                marginLeft: 4,
              }}
            >
              {details?.title}
            </NtText>
          </View>
          <NtText
            style={{
              fontSize: 14,
              fontWeight: "500",
              color: colors.almostBlack,
              marginLeft: 10,
            }}
          >
            {value?.value}
          </NtText>
        </View>
      );
    } else {
      return (
        <NtText
          style={{
            fontSize: 14,
            fontWeight: "500",
            // color: value ? colors.almostBlack : colors.lighGray,
            color: colors.lighGray,
            marginLeft: 4,
          }}
        >
          {placeholder}
        </NtText>
      );
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.title, { marginLeft: 10 }]}>{title}</NtText>
      <NtTouchableEffect
        style={[
          {
            paddingLeft: 10,
            paddingRight: 10,
            height: 45,
            backgroundColor: colors.lighestGray,
            borderRadius: 30,
            justifyContent: "center",
            marginTop: 4,
          },
        ]}
        onPress={() => {
          accountPickerRef.current.show();
        }}
      >
        {renderValue()}
      </NtTouchableEffect>
      {renderAccountPickerModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtModalAccountPickerField;
