import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtTabController from "../../components/NtTabController";
import ringgroupstrategytypesdata from "../data/ringgroupstrategytypesdata";
import NtModalError from "../../components/NtModalError";
import { createInboundRoute } from "../../api/inboundroute";
import { createVoicemailBox } from "../../api/voicemail";
import NtModalAccountPickerField from "./NtModalAccountPickerField";
import { createHardwareFile } from "../../api/hardware";
import { parseExtension } from "../utils/ExtensionUtils";
import hardwareaccountsdata from "../data/hardwareaccountsdata";

function NtHardwareNewForm({ onCancel, onCreate }) {
  const [mac, setMac] = useState("");
  const [extension, setExtension] = useState("");
  const [proxy, setProxy] = useState("");
  const [port, setPort] = useState("5060");
  const [account, setAccount] = useState(hardwareaccountsdata[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return mac && extension && proxy && port;
  };

  const handleCreate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      mac: mac,
      extension: extension?.item?.extension,
      password: extension?.item?.password,
      proxy: proxy,
      port: port,
      account: account?.id,
      domain: context.user?.environment?.domain,
      name:
        extension?.item?.effective_caller_id_name ||
        extension?.item?.extension.substring(4),
    };

    const response = await createHardwareFile(params);

    Logger("Hardware Create", response);

    if (response.ok) {
      if (onCreate) {
        onCreate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const renderAccounts = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Account *
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={hardwareaccountsdata}
          selection={account}
          onPress={(item) => {
            setAccount(item);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Add new Hardware</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Add new Hardware Configuration for IP Grandstream Phone
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        title={"Mac *"}
        placeholder={"Mac *"}
        value={mac}
        setValue={(text) => setMac(text)}
      />

      <NtModalAccountPickerField
        containerStyle={{ marginTop: 25 }}
        title={"Extension *"}
        placeholder={"Extension *"}
        value={extension}
        removeOptions={["ringgroup", "voicemail", "other"]}
        setValue={(text) => {
          Logger("----------- selected Extension", text);
          setExtension(text);
        }}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        title={"Proxy *"}
        placeholder={"Proxy *"}
        value={proxy}
        setValue={(text) => setProxy(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        title={"Port *"}
        placeholder={"Port *"}
        value={port}
        setValue={(text) => setPort(text)}
      />

      {renderAccounts({ marginTop: 25 })}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"Create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtHardwareNewForm;
