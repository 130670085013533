import Logger from "../common/utils/Logger";
import client from "./client";

export const fetchHardware = (status, page, pageSize, search, filter) => {
  let endpoint = `/v2/pbx/hardware/provisioning/file/${status}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  //sort
  if (filter?.orderBy) {
    endpoint += `&orderby=creationdate&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=creationdate&order=DESC`;
  }

  Logger("Final endpoint for requesting ringgroups: ", endpoint);

  return client.get(endpoint);
};

export const fetchProvisioningFile = (status, mac) => {
  let endpoint = `/v2/pbx/hardware/provisioning/file/${status}/mac/${mac}`;

  return client.get(endpoint);
};

export const createHardwareFile = (params) => {
  let endpoint = `/v2/pbx/hardware/provisioning/config/grp2612/${params?.mac}`;

  const body = {
    extension: params?.extension,
    password: params?.password,
    domain: params?.domain,
    outboundProxy: params?.proxy,
    outboundProxyPort: params?.port,
    account: params?.account,
    name: params?.name,
  };

  return client.post(endpoint, body);
};

export const deleteHardwareFile = (mac, status) => {
  let endpoint = `/v2/pbx/hardware/provisioning/file/${status}/mac/${mac}`;

  return client.delete(endpoint);
};
