import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseController from "../../common/base/BaseController";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtListHeader from "../../components/NtListHeader";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtListEmpty from "../../components/NtListEmpty";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import NtInboundRouteItem from "../components/NtInboundRouteItem";
import NtModal from "../../components/NtModal";
import NtInboundRouteNewForm from "../components/NtInboundRouteNewForm";
import { fetchInboundRoutes } from "../../api/inboundroute";
import inboundroutecsvcolumndata from "../data/inboundroutecsvcolumndata";

class InboundRouteController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
  };

  column_location = "@inboundroute_column_storage";

  page = 1;

  addModalRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      inboundroutecsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    Logger("Fetching Inbound Routes data");
    this.setState({ loading: true });
    const response = await fetchInboundRoutes(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText
    );
    Logger("Data response GET: ", response);
    if (response.ok) {
      Logger("Loading data total items", response.data?.dialplans?.length);
      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.dialplans,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");
        this.setState({
          data: this.state.data.concat(response.data?.dialplans),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Inbound Routes export data");

    const response = await fetchInboundRoutes(
      this.context.user?.environment?.domain,
      1,
      100000,
      this.state.searchText
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      //will filter base on the tab....
      return response.data.dialplans;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "dialplan_destination_actions") {
      return `"${JSON.stringify(item[column]).replace(/['"]+/g, "")}"`;
    } else if (column == "dialplan_number") {
      return `"${item[column]}"`;
    }

    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addModalRef}>
        <NtInboundRouteNewForm
          onCancel={() => {
            this.addModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.page = 1;
            this.handleDataFetch();
            this.addModalRef.current?.dismiss();
            showToast("Success", "Successfully created the new inbound route");
          }}
        />
      </NtModal>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtInboundRouteItem
        key={index}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully updated Inbound Route ${item?.ring_group_extension}`
          );
        }}
        onDelete={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully deleted Inbound Route ${item?.ring_group_extension}`
          );
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Inbound Routes"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={inboundroutecsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"inboundroutes"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default InboundRouteController;
